import {GetAllRefIntegrityConsequencesQuery, IntegrityStatus, UpdateIntegrityStatusByIdMutation} from '@app/graphql/__types__/graphql';
import {FORMAT_DATE_EU, INTEGRITY_CONDITION_COLOR} from '@app/utils/constants';
import {RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem, RadBadge, RadDropdownMenu, RadDropdownMenuTrigger, RadDropdownMenuContent, RadDropdownMenuItem} from '@holis/react-ui/rad';
import moment from 'moment';
import {LuExternalLink, LuCalendarDays, LuLineChart} from 'react-icons/lu';
import ItemCard, {ItemCardContent} from '../../ItemCard';
import LabelValueRow from '@app/components/Common/Form/LabelValueRow';
import {TextareaHTMLAttributes, useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {INTEGRITY_REF_CONSEQUENCES_GET_ALL, INTEGRITY_STATUSES_UPDATE_BY_ID} from '@app/graphql/requests';
import _ from 'lodash';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import {useLayoutStore} from '@app/stores/layout';
import MeasurementValueContainer from '../../ItemCard/MeasurementValueContainer';
import {tailwindColorToBgFgStyle} from '@app/utils/functions';

type TIntegrityStatusCard = Readonly<{
  integrityStatus: Partial<IntegrityStatus>;
  hasMenuContext?: boolean;
  className?: string;
  readonly?: boolean;
}>;
export default function IntegrityStatusCard({integrityStatus, className, hasMenuContext, readonly}: TIntegrityStatusCard) {
  const floc = integrityStatus.functionalLocation!;
  const [ints, setInts] = useState<Partial<IntegrityStatus>>();
  const [notes, setNotes] = useState<string>();
  const {startLoading, stopLoading} = useLayoutStore();
  const {t} = useTranslation();
  const [updateIntegrityStatusApi] = useMutation<UpdateIntegrityStatusByIdMutation>(INTEGRITY_STATUSES_UPDATE_BY_ID);
  const {data: refIntegrityConsequences} = useQuery<GetAllRefIntegrityConsequencesQuery>(INTEGRITY_REF_CONSEQUENCES_GET_ALL);
  const handleUpdateIntsField = (field: string, value: unknown) => {
    if (!_.isEqual(value ?? '', ints?.[field as keyof IntegrityStatus] ?? '')) {
      startLoading();
      updateIntegrityStatusApi({
        variables: {
          id: integrityStatus.id,
          data: {
            [field]: {
              set: value,
            },
          },
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        AppNotifications.success(t('message.success.intsUpdated'));
        setInts(queryResult.data?.updateOneIntegrityStatus as Partial<IntegrityStatus>);
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'));
      }).finally(stopLoading);
    }
  };

  useEffect(() => {
    setInts(integrityStatus);
  }, [integrityStatus.id]);

  useEffect(() => {
    setNotes(ints?.notes ?? '');
  }, [ints]);

  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <ItemCard className={className}>

          <ItemCardContent className='flex items-center gap-2'>
            <MeasurementValueContainer measurementValue={integrityStatus.integrityCondition?.condition} measurementColor={integrityStatus.integrityCondition?.color ?? INTEGRITY_CONDITION_COLOR as string}/>

            <div className='flex-1 flex flex-col py-1 gap-1 self-stretch'>
              <div className='flex gap-1 items-center'>
                {/* {floc.techClass && <RadBadge className='bg-primary text-white'>{floc.techClass.techClass}</RadBadge>} */}
                <div className='flex flex-col gap-1'>
                  <div className='font-semibold'>{floc.floc}</div>
                  <div>{floc.description}</div>
                </div>
              </div>
              <LabelValueRow
                field='notes'
                className='my-2'
                inputComponent='textarea'
                value={notes}
                label={false}
                isDisabled={readonly}
                inputProps={{
                  rows: 2,
                  disabled: readonly,
                  className: 'min-h-[24px] border-0 p-[10px] hover:border hover:p-[9px] focus:p-[9px] hover:bg-gray-50 focus:border text-xs',
                  onBlur: () => handleUpdateIntsField('notes', notes),
                } as TextareaHTMLAttributes<HTMLTextAreaElement>}
                handleFieldChange={(field, value) => setNotes(String(value))}
              />
              <div className='flex flex2 text-gray-500'>
                <div className='flex items-center gap-1'><LuCalendarDays/><span className='mr-4'>{t('label.maxEndDate')}</span> <span className='font-bold text-sm'>{integrityStatus.maxEndDate ? moment.parseZone(integrityStatus.maxEndDate!).format(FORMAT_DATE_EU) : '-'}</span></div>
                <div className='flex items-center gap-1 ml-12'><LuLineChart/><span className='mr-4'>{t('label.corrRateExt')}</span> <span className='font-bold text-sm'>{integrityStatus.corrRateExt ?? '-'}</span></div>
                <div className='flex items-center gap-1 ml-12'><LuLineChart/><span className='mr-4'>{t('label.corrRateInt')}</span> <span className='font-bold text-sm'>{integrityStatus.corrRateInt ?? '-'}</span></div>
                <div className='flex items-center gap-1 ml-12'><LuLineChart/>
                  <span className='mr-4'>{t('label.consequence')}</span>

                  <RadDropdownMenu>
                    <RadDropdownMenuTrigger disabled={readonly}>
                      <RadBadge style={tailwindColorToBgFgStyle(ints?.integrityConsequence?.color as string)} className={`${readonly ? 'opacity-90' : 'hover:opacity-90'} focus:ring-transparent`}>
                        <span className='font-bold text-sm '>[{ints?.integrityConsequence?.consequence ?? '-'}] {ints?.integrityConsequence?.description ?? '-'}</span>
                      </RadBadge>
                    </RadDropdownMenuTrigger>
                    <RadDropdownMenuContent>
                      {refIntegrityConsequences?.refIntegrityConsequences.map(consequence => (
                        <RadDropdownMenuItem key={consequence.consequence} className='cursor-pointer' onClick={() => handleUpdateIntsField('consequence', consequence.consequence)}>
                          <RadBadge style={tailwindColorToBgFgStyle(consequence.color ?? undefined)} className='w-full'>
                            <span className='font-bold text-sm'>[{consequence.consequence}] {consequence.description}</span>
                          </RadBadge>
                        </RadDropdownMenuItem>
                      ))}
                    </RadDropdownMenuContent>
                  </RadDropdownMenu>
                </div>
              </div>
            </div>
          </ItemCardContent>

        </ItemCard>
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem>
          <LuExternalLink className='mr-2'/> {t('label.open')}
        </RadContextMenuItem>
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
