import {gql} from '@apollo/client';

export const FLOC_CHARACTERISTICS_COMMON_FIELDS = gql`
    fragment RefFlocCharacteristicCommonFields on RefFlocCharacteristic {
        id
        characteristic
        description
        group
    }
`;
