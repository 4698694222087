import {Event, EventInspectionPoint, InspectionPoint, Picture, UpdateEventInspectionPointByIdMutation, UpdateInspectionPointByIdMutation} from '@app/graphql/__types__/graphql';
import MultiplePanelsModal, {TMultiplePanelsModal} from '@app/components/Modal/NewMultiplePanels';
import {TCarouselApi} from '@holis/react-ui';
import React, {useRef, useState} from 'react';
import Header from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/Header';
import Footer from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/Footer';
import PictureCarousel from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/pictures/PictureCarousel';
import EventInspectionPointBlock from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/events/EventInspectionPointsBlock';
import GeneralInfo from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/GeneralInfo';
import Actions from '@app/components/Common/Block/InspectionPoint/InspectionPointModal/components/Actions';
import useInspectionPointStore from '@app/stores/insp';
import DeleteInspectionPointModal from './components/DeleteInspectionPointModal';
import {EApiOperator} from '@app/utils/enums';
import {OBJ_NEW_ID} from '@app/utils/constants';
import MeasurementBlock from './components/measurement';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {useMutation} from '@apollo/client';
import {INSPECTION_POINTS_UPDATE_BY_ID, WORKORDER_EVENT_INSPECTION_POINTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import {useHolisAuth} from '@holis/auth-client-react';
type TInspectionPointModal = TMultiplePanelsModal<Partial<InspectionPoint>> & Readonly<{
  event?: Partial<Event>;
  eventInspectionPoint?: Partial<EventInspectionPoint>;
  onCreatedOrUpdated?: (inspectionPoint?: Partial<InspectionPoint>, operator?: EApiOperator) => void;
  onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
}>

export default function InspectionPointModal({item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventInspectionPoint, ...restProps}: TInspectionPointModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID;
  const {t} = useTranslation();
  const prms = useUserPermissions();
  const {user} = useHolisAuth();
  const [setCurrentDateAndUser, setSetCurrentDateAndUser] = useState<boolean>(false);
  const {deleteInspectionPointModalOpen, resetData, changeDeleteInspectionPointModalDisplay, editInspectionPoint} = useInspectionPointStore();
  const datasToSaveGi = useRef<Partial<InspectionPoint>>({});
  const datasToSaveMeas = useRef<Partial<EventInspectionPoint>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [updateInspectionPointByIdApi] = useMutation<UpdateInspectionPointByIdMutation>(INSPECTION_POINTS_UPDATE_BY_ID);
  const [updateEventInspByIdApi] = useMutation<UpdateEventInspectionPointByIdMutation>(WORKORDER_EVENT_INSPECTION_POINTS_UPDATE_BY_ID);

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>();

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api);
  };

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination();
  };

  const handleCloseModal = () => {
    resetData();
    onOpenChange?.(false);
  };

  const handleInspDeleted = () => {
    handleCloseModal();
    onCreatedOrUpdated?.(item, EApiOperator.DELETE);
  };

  const handleFieldUpdated = (type: 'generalInfos' | 'measurement', field: string, value?: unknown) => {
    if ((value as { id: string })?.id) {
      value = (value as { id: string }).id;
    }

    if (field === 'qualReadingId') {
      setSetCurrentDateAndUser(true);
    }

    if (type === 'generalInfos') {
      datasToSaveGi.current = {
        ...datasToSaveGi.current,
        [field]: value,
      };
    }

    if (type === 'measurement') {
      datasToSaveMeas.current = {
        ...datasToSaveMeas.current,
        [field]: value,
      };
    }

    setIsDirty(true);
  };

  const handleSave = async () => {
    const updateInspRequest: Record<string, unknown> = {};
    const updateEvtInspRequest: Record<string, unknown> = {};
    Object.keys(datasToSaveGi.current).forEach(field => {
      updateInspRequest[field] = {set: datasToSaveGi.current[field as keyof InspectionPoint] ?? null};
    });
    Object.keys(datasToSaveMeas.current).forEach(field => {
      updateEvtInspRequest[field] = {set: datasToSaveMeas.current[field as keyof EventInspectionPoint] ?? null};
    });

    try {
      if (Object.keys(datasToSaveGi.current).length) {
        await updateInspectionPointByIdApi({variables: {id: item.id, data: updateInspRequest}});
      }

      if (Object.keys(datasToSaveMeas.current).length) {
        updateEvtInspRequest.reader = {set: user?.username};
        updateEvtInspRequest.reportingDate = {set: new Date()};
        await updateEventInspByIdApi({variables: {id: eventInspectionPoint!.id, data: updateEvtInspRequest}});
      }

      datasToSaveGi.current = {};
      datasToSaveMeas.current = {};
      setIsDirty(false);
      onCreatedOrUpdated?.(item, isNew ? EApiOperator.CREATE : EApiOperator.UPDATE);
      handleCloseModal();
      AppNotifications.success(t('message.success.inspectionPointUpdated'));
    } catch (e) {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  return (
    <MultiplePanelsModal
      autoSaveId='modal-insp'
      header={<Header actions={isNew ? null : <Actions inspectionPoint={item} canDelete={prms.inspectionPoints.delete} onCreatedOrUpdated={onCreatedOrUpdated}/>} inspectionPoint={item}/>}
      footer={<Footer inspectionPoint={item} saveButtonEnabled={isDirty} onCancelClick={() => setIsModalOpened(false)} onSaveClick={handleSave}/>}
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={() => !isDirty}
      onOpenChange={opened => {
        if (opened) {
          setIsModalOpened(true);
        } else {
          handleCloseModal();
        }
      }}
      {...restProps}
      isOpen={isModalOpened}
    >
      <GeneralInfo readonly={!prms.inspectionPoints.update} inspectionPoint={item} onFieldChange={(f, v) => handleFieldUpdated('generalInfos', f, v)}/>
      <div className='flex flex-col w-full overflow-auto'>
        {!!eventInspectionPoint && <MeasurementBlock readonly={!prms.inspectionPoints.measurement} event={event!} inspectionPoint={editInspectionPoint!} eventInspectionPoint={eventInspectionPoint} useCurrentDateAndUser={setCurrentDateAndUser} onFieldChange={(f, v) => handleFieldUpdated('measurement', f, v)}/>}
        <PictureCarousel readonly={!prms.inspectionPoints.measurement} inspectionPoint={item} event={event} onCarouselRender={handlePictureCarouselRender} onChanged={onPicturesChanged}/>
        <EventInspectionPointBlock event={event} inspectionPoint={editInspectionPoint!}/>
        <DeleteInspectionPointModal open={deleteInspectionPointModalOpen} inspectionPoint={item} changeDeleteInspectionPointModalDisplay={changeDeleteInspectionPointModalDisplay} onInspectionPointDeleted={handleInspDeleted}/>
      </div>
    </MultiplePanelsModal>
  );
}
