/* eslint-disable max-depth */
/* eslint-disable complexity */

import {FetchResult, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import ElevatedButton from '@app/components/Common/Button/ElevatedButton';
import PrimaryButton from '@app/components/Common/Button/ElevatedButton/PrimaryButton';
import TextButton from '@app/components/Common/Button/TextButton';
import FormIcon, {FIRST_OBJ_INVISIBLE_LIST} from '@app/components/Common/Markup/FormIcon';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {Cml, CmlUncheckedUpdateInput, Damage, DamageUncheckedUpdateInput, Event, EventCml, EventDamage, FunctionalLocation, GetAllRefCmlValCodesQuery, GetAllRefDamageValCodesQuery, GetAllRefIntegrityConditionsQuery, GetLatestEventCmlByCmlIdQuery, GetLatestEventDamageByDmgIdQuery, InspectionDrawing, Notification, NotificationDamage, RefCmlValCode, RefDamageValCode, RefIntegrityCondition, UpdateCmlByIdMutation, UpdateDamageByIdMutation} from '@app/graphql/__types__/graphql';
import {CML_REF_VAL_CODES_GET_MANY, DAMAGES_UPDATE_BY_ID, DAMAGE_REF_VAL_CODES_GET_MANY, INTEGRITY_REF_CONDITIONS_GET_MANY, WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID} from '@app/graphql/requests';
import {CMLS_UPDATE_BY_ID} from '@app/graphql/requests/cmls';
import AppNotifications from '@app/services/notification';
import useCmlStore from '@app/stores/cml';
import useDamageStore from '@app/stores/damage';
import {EventState, useEventStore} from '@app/stores/event';
import useIdwgStore, {CML_PREFIX, DAMAGE_PREFIX, deleteIdwgStore, IDWG_PREFIX, TAB_CMLS, TAB_DAMAGES} from '@app/stores/idwg';
import useNotificationStore, {NotificationState} from '@app/stores/notification';
import {TDimension, TFabricObjectWithPrivate, TMarkupObjects} from '@app/types/app';
import {DEFAULT_SHAPE_COLOR, MARKUP_BACKGROUND_ID} from '@app/utils/constants';
import {EDMGMarkupForm, EDownloadFileType, EDrawingFileContentType, EEventFlocObjectFilter, EFlocRightSideTab, EIanRightSideTab, EInspectionStatus, EVerticalPosition, EWorkpackRightSideTab} from '@app/utils/enums';
import {createBase64FromUrl, reactElementToString} from '@app/utils/functions';
import {LOCK_RESIZE_ROTATE, createSvgAnnotation, handleObjectMoved, updateLastMarkupPosition} from '@app/utils/functions/fabric';
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig';
import {UniqueIdentifier} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useHolisAuth} from '@holis/auth-client-react';
import {Badge, Button, TListItem} from '@holis/react-ui';
import {RadCommand, RadCommandGroup, RadCommandItem} from '@holis/react-ui/rad';
import * as fabric from 'fabric';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BsFillGrid3X3GapFill} from 'react-icons/bs';
import {FaExternalLinkAlt, FaInfo} from 'react-icons/fa';
import {LuPlusSquare} from 'react-icons/lu';
import {TbPlayerSkipBack, TbPlayerSkipForward} from 'react-icons/tb';
import {ReactZoomPanPinchRef, ReactZoomPanPinchState} from 'react-zoom-pan-pinch';
import styled from 'styled-components';
import {twMerge} from 'tailwind-merge';
import colors from 'tailwindcss/colors';
import DeleteIdwgModal from './DeleteIdwgModal';
import DeleteIdwgFlocModal from './Floc/DeleteIdwgFlocModal';
import DeleteIdwgGridModal from './Grid/DeleteIdwgGridModal';
import InspectionInfo from './Inspection/InspectionInfo';
import {FlocState, useFlocStore} from '@app/stores/methodEngineering/floc';
import {useLocation} from 'react-router-dom';
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import {AxiosError} from 'axios';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';

type TDrawingViewer = Readonly<{
  idwg: Partial<InspectionDrawing>
  canvasRef?: React.RefObject<HTMLCanvasElement>
  canvasContainerRef?: React.RefObject<HTMLDivElement>;
  onViewerInit?: (ref: ReactZoomPanPinchRef) => void;
  sortableId?: UniqueIdentifier;
  objectItem?: Partial<Event | Notification | FunctionalLocation>;
  hasActionList?: boolean;
  size?: number;
  cmls?: Partial<Cml>[];
  damages?: Partial<Damage>[];
  showIdwgDetailButton?: boolean;
  isSelected?: boolean;
  storeId?: string;
  canvasDisabled?: boolean;
}>;

const StyledViewerContainer = styled.div`
    & .canvas-container{
        margin: auto;
        overflow: hidden;
        top: 50%;
        position: relative;
        transform: translateY(-50%);
//        border: 5px solid lightgray;
        background: white;
    }
    & .select-drawing-btn{
      display: none;
    }
    /* & canvas {
      cursor: inherit !important;
    } */
`;

const originPoint = new fabric.Point({x: 0, y: 0});

type TPanningInfo = {
    isDragging: boolean;
    x: number;
    y: number;
}
fabric.FabricObject.prototype.noScaleCache = true;
fabric.FabricObject.prototype.objectCaching = false;
fabric.FabricObject.prototype.dirty = false;

/**
 * Drawing viewer (add or show markup for damage, cml)
 */
export default function DrawingViewer({idwg, showIdwgDetailButton, canvasRef, canvasContainerRef, onViewerInit: _onViewerInit, sortableId, hasActionList, objectItem, size, cmls: idwgCmls, damages: idwgDamages, isSelected, storeId, canvasDisabled}: TDrawingViewer) {
  const prms = useUserPermissions();
  const {t} = useTranslation();
  const config = useOptimusConfig();
  const location = useLocation();
  const lastMarkupPositions = useRef<Record<string, Partial<Record<keyof TMarkupObjects, fabric.Point>>>>({});
  const canvasMenuContextRef = React.createRef<HTMLDivElement>();
  const eventState = useEventStore();
  const notificationState = useNotificationStore();
  const flocState = useFlocStore();
  const {notificationDamages, lastDamageUpdated: lastNotifDamageUpdated, lastDamageUpdatedAt: lastNotifDamageUpdatedAt} = notificationState;
  const {cmls: flocCmls, damages: flocDamages} = flocState;
  const {filteredEventCmls, filteredEventDamages, eventCmls, eventDamages, eventFlocCmlFilter, filteredEventFlocCmls, filteredEventFlocDamages, eventFlocDamageFilter, lastCmlUpdated, lastDamageUpdated, lastDamageUpdatedAt, lastCmlUpdatedAt, eventInspectionDrawings} = eventState;
  const clickedMarkupPosition = useRef<fabric.Point>();
  const [storeInit, setStoreInit] = useState<boolean>();
  const {setCanvasInfos, setCanvas, setBgInfos, canvas, bgImg, allMarkups, viewerState, bgDimension, setViewerState, setScale, scale, modalIdwgDetailShown, changeModalIdwgDetailShown, uploadFile, flocToDelete, gridToDelete, selectedTab, cmls, setCmls, damages, setDamages, addCmlMarkup, addDamageMarkup, setActiveMarkupId, deleteInspectionDrawingModalOpen, activeInspectionDrawing, activeMarkupId, removeCmlMarkup, removeDamageMarkup, lastCmlUpdated: lastIdwgCmlUpdated, lastDamageUpdated: lastIdwgDamageUpdated, lastDamageUpdatedAt: lastIdwgDamageUpdatedAt, lastCmlUpdatedAt: lastIdwgCmlUpdatedAt, rightSideWidth: idwgContainerWidth} = useIdwgStore(storeId ?? `${IDWG_PREFIX}${idwg.id}`, {
    activeInspectionDrawing: idwg,
  });
  const objectItemId = objectItem ? `${objectItem?.__typename}${objectItem?.id}` : null;
  const objectItemState: EventState | NotificationState | FlocState | undefined = useMemo(() => {
    if (objectItem?.__typename === 'Event') {
      return eventState;
    }

    if (objectItem?.__typename === 'Notification') {
      return notificationState;
    }

    if (objectItem?.__typename === 'FunctionalLocation') {
      return flocState;
    }

    return undefined;
  }, [objectItemId, eventState, notificationState, flocState]);

  const eventIdwg = eventInspectionDrawings?.find(evtIdwg => evtIdwg.idwgId === idwg.id);

  const [selectedDrawing, rightSideWidth, rightSideTabSelectedValue, setSelectedDrawing, nextSelectedDrawing, prevSelectedDrawing] = useMemo(() => [isSelected ? idwg : objectItemState?.selectedDrawing, objectItemState?.rightSideWidth, objectItemState?.rightSideTabSelectedValue, objectItemState?.setSelectedDrawing, objectItemState?.nextSelectedDrawing, objectItemState?.prevSelectedDrawing], [objectItemState, isSelected, idwg]);

  const idwgViewerWidth = rightSideWidth ?? idwgContainerWidth;

  const [isDrawingsTabSelected, isSelectedDrawing] = useMemo(() => [!!objectItem && ((objectItem.__typename === 'Event' && rightSideTabSelectedValue === EWorkpackRightSideTab.DRAWINGS) || (objectItem.__typename === 'Notification' && rightSideTabSelectedValue === EIanRightSideTab.DRAWINGS) || (objectItem.__typename === 'FunctionalLocation' && rightSideTabSelectedValue === EFlocRightSideTab.DRAWINGS)), isSelected || selectedDrawing?.id === idwg.id! || !objectItem], [rightSideTabSelectedValue, objectItemState, isSelected, idwg, objectItemState, objectItemId]);
  const {cmlMarkupsShown, damageMarkupsShown, changeCmlMarkupsDisplay, changeDamageMarkupsDisplay} = useIdwgStore();
  const {updateDamage, createNewDamageMarkup, setActiveDamage} = useDamageStore();
  const {updateCml, createNewCmlMarkup, setActiveCml} = useCmlStore();
  const [canvasMenuContextShown, setCanvasMenuContextShown] = useState<boolean>(false);
  // const [getIdwgDetailApi] = useLazyQuery<GetInspectionDrawingDetailQuery>(INSPECTION_DRAWINGS_GET_DETAIL);
  const [viewerSize, setViewerSize] = useState<number|undefined>();
  const {docName} = activeInspectionDrawing ?? idwg ?? {};
  const panningInfo = useRef<TPanningInfo>({isDragging: false, x: 0, y: 0});
  const canvasObj = useRef<fabric.Canvas>();
  const viewerRef = useRef<ReactZoomPanPinchRef>();
  canvasRef = canvasRef ?? React.createRef<HTMLCanvasElement>();
  canvasContainerRef = canvasContainerRef ?? React.createRef<HTMLDivElement>();
  const {getAccessToken} = useHolisAuth();
  const [svgSrc, setSvgSrc] = useState<string | null>();
  const refNewMarkupPosition = useRef<fabric.Point>();
  const refCreateNewMarkup = useRef<boolean>(false);
  const [error, setError] = useState<React.ReactNode>();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateCmlByIdApi] = useMutation<UpdateCmlByIdMutation>(CMLS_UPDATE_BY_ID);
  const [updateDamageByIdApi] = useMutation<UpdateDamageByIdMutation>(DAMAGES_UPDATE_BY_ID);
  const refDamageValCodesResult = useQuery<GetAllRefDamageValCodesQuery>(DAMAGE_REF_VAL_CODES_GET_MANY);
  const [getLatestEventCml] = useLazyQuery<GetLatestEventCmlByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID);
  const [getLatestEventDamage] = useLazyQuery<GetLatestEventDamageByDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID);

  const updateDamageDataField = async (id: number, field: keyof DamageUncheckedUpdateInput, value: number | string): Promise<boolean> => updateDamageByIdApi({
    variables: {
      id,
      data: {
        [field]: {
          set: value,
        },
      },
    },
  }).then((res: FetchResult<UpdateDamageByIdMutation>) => {
    if (!res.errors) {
      updateDamage(res.data?.updateOneDamage as Partial<Damage>);
      AppNotifications.success(t('message.success.damageUpdated'));
      return true;
    }

    const errorMessage: string = t('message.error.default.title');
    AppNotifications.error(errorMessage);
    return false;
  }).catch(_error => {
    const errorMessage: string = t('message.error.default.title');
    AppNotifications.error(errorMessage);
    return false;
  });
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: sortableId ?? `${idwg.id}`});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const refCmlValCodesResult = useQuery<GetAllRefCmlValCodesQuery>(CML_REF_VAL_CODES_GET_MANY);
  const refIntegrityConditionsResult = useQuery<GetAllRefIntegrityConditionsQuery>(INTEGRITY_REF_CONDITIONS_GET_MANY);
  const updateCmlDataField = async (id: number, field: keyof CmlUncheckedUpdateInput, value: number | string): Promise<boolean> => updateCmlByIdApi({
    variables: {
      id,
      data: {
        [field]: {
          set: value,
        },
      },
    },
  }).then((res: FetchResult<UpdateCmlByIdMutation>) => {
    if (!res.errors) {
      updateCml(res.data?.updateOneCml as Partial<Cml>);
      AppNotifications.success(t('message.success.cmlUpdated'));
      return true;
    }

    const errorMessage: string = t('message.error.default.title');
    AppNotifications.error(errorMessage);
    return false;
  }).catch((_error: Error) => {
    const errorMessage: string = t('message.error.default.title');
    AppNotifications.error(errorMessage);
    return false;
  });

  const handleDisplayCmlClick = () => {
    changeCmlMarkupsDisplay(!cmlMarkupsShown);
  };

  const handleDisplayDamagesClick = () => {
    changeDamageMarkupsDisplay(!damageMarkupsShown);
  };

  const fitToScreen = (state?: ReactZoomPanPinchState) => {
    setTimeout(() => {
      setViewerState({
        ...state,
        zoomPoint: originPoint,
        scale: 1,
      });
    }, 100);
  };

  const getInspectionStatusBgColor = (status?: string | null) => {
    switch (status) {
      case EInspectionStatus.COMPLETED:
        return 'bg-green-500';
      case EInspectionStatus.HOLD:
        return 'bg-gray-500';
      case EInspectionStatus.PROCESSING:
        return 'bg-blue-500';
      default:
        return 'bg-transparent';
    }
  };

  // const getInspectionStatusTextColor = (status?: string | null) => {
  //   switch (status) {
  //     case EInspectionStatus.COMPLETED:
  //       return 'text-green-700';
  //     case EInspectionStatus.HOLD:
  //       return 'text-gray-700';
  //     case EInspectionStatus.PROCESSING:
  //       return 'text-blue-700';
  //     default:
  //       return 'text-transparent';
  //   }
  // };

  // const getIdwgDetail = () => {
  //   getIdwgDetailApi({
  //     variables: {
  //       id: idwg!.id,
  //     },
  //     fetchPolicy: 'no-cache',
  //   }).then((queryResult: QueryResult<GetInspectionDrawingDetailQuery>) => {
  //     const detailIdwg: Partial<InspectionDrawing> = (queryResult.data?.inspectionDrawing ?? {}) as Partial<InspectionDrawing>;
  //     updateInspectionDrawing(detailIdwg);
  //     setIdwgFlocs(detailIdwg.idwgFlocs);
  //     setIdwgGrids(detailIdwg.idwgGrids);
  //   });
  // };

  const showIdwgDetail = () => {
    changeModalIdwgDetailShown(true);
  };

  const handleScreenFitClick = (canvasRef?: React.RefObject<HTMLCanvasElement>, transformComponentRef?: ReactZoomPanPinchRef) => {
    panningInfo.current.isDragging = false;
    if (transformComponentRef) {
      canvas?.absolutePan(originPoint);
      transformComponentRef.setTransform(0, 0, 1);
    }

    fitToScreen(transformComponentRef?.state);
  };

  const loadBgImg = async (svgUrl: string, originalDimension?: TDimension, isCurrentBg?: boolean) => {
    setLoading(true);
    const imgDoc = new Image();
    imgDoc.onload = (_event: unknown) => {
      setBgInfos(imgDoc, {width: originalDimension?.width ?? imgDoc.width, height: originalDimension?.height ?? imgDoc.height}, !!isCurrentBg);
    };

    // eslint-disable-next-line max-params
    imgDoc.onerror = (_event: unknown, _source?: string, _lineno?: number, _colno?: number, error?: Error) => {
      hideLoading(() => {
        setError(error?.message ?? true);
      });
    };

    const imgContent = await createBase64FromUrl(svgUrl, {getAccessToken, onFail(err) {
      setError(err instanceof AxiosError && err.status === 404 ? t('message.error.fileNotFound') : true);
    }});
    if (imgContent) {
      imgDoc.src = imgContent;
    } else {
      imgDoc.src = '';
    }
  };

  // const handlePanningStart = (ref: ReactZoomPanPinchRef, e: MouseEvent | TouchEvent, canvas?: fabric.Canvas) => {
  //   canvas = canvas ?? canvasObj.current;
  //   if (canvas && e instanceof MouseEvent) {
  //     // canvas.selection = false;
  //     panningInfo.current.isDragging = true;
  //     panningInfo.current.x = e.clientX;
  //     panningInfo.current.y = e.clientY;
  //   }
  // };

  // const handlePanningStop = (ref: ReactZoomPanPinchRef, e: MouseEvent | TouchEvent, canvas?: fabric.Canvas) => {
  //   canvas = canvas ?? canvasObj.current;
  //   if (canvas) {
  //     canvas.setViewportTransform(canvas.viewportTransform!);
  //     panningInfo.current.isDragging = false;
  //     // canvas.selection = true;
  //   }
  // };

  // const handlePanning = (ref: ReactZoomPanPinchRef, e: MouseEvent | TouchEvent, canvas?: fabric.Canvas) => {
  //   canvas = canvas ?? canvasObj.current;
  //   // e.preventDefault();
  //   // e.stopPropagation();
  //   // const currentZoomPoint = viewerState?.zoomPoint ?? originPoint;
  //   // const newZoomPoint = new fabric.Point(currentZoomPoint.x - ref.state.positionX, currentZoomPoint.y - ref.state.positionY);
  //   if (canvas && panningInfo.current.isDragging && e instanceof MouseEvent) {
  //     const vpt: fabric.TMat2D = canvas.viewportTransform!;
  //     vpt[4] += e.clientX - panningInfo.current.x;
  //     vpt[5] += e.clientY - panningInfo.current.y;
  //     canvas.requestRenderAll();
  //     panningInfo.current.x = e.clientX;
  //     panningInfo.current.y = e.clientY;
  //   }
  // };

  // const handleScreenZoom = (ref: ReactZoomPanPinchRef, e: TouchEvent | MouseEvent | WheelEvent) => {
  //   // e.preventDefault();
  //   // e.stopPropagation();
  //   if (e instanceof MouseEvent || e instanceof WheelEvent) {
  //     setViewerState({...ref.state, zoomPoint: new fabric.Point(e.offsetX, e.offsetY)});
  //   }
  // };

  // const handleInit = (ref: ReactZoomPanPinchRef) => {
  //   viewerRef.current = ref;
  //   onViewerInit?.(ref);
  // };

  const handleMouseDownClickOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:down']) => {
    const mouseType = (cEvent.e as {button?: number}).button;
    const position = cEvent.scenePoint;
    panningInfo.current.x = (cEvent.e as MouseEvent).clientX;
    panningInfo.current.y = (cEvent.e as MouseEvent).clientY;
    if (mouseType === 2) {
      // Disable menu context if out of box
      if (position.x < 0 || position.x > canvas?.backgroundImage?.width! || position.y < 0 || position.y > canvas?.backgroundImage?.height!) {
        return;
      }

      cEvent.e.preventDefault();
      cEvent.e.stopPropagation();
      cEvent.e.stopImmediatePropagation();
    } else if (cEvent.target) {
      cEvent.e.preventDefault();
      cEvent.e.stopPropagation();
      cEvent.e.stopImmediatePropagation();
    } else if (panningInfo.current.isDragging) {
      panningInfo.current.isDragging = false;
    } else {
      panningInfo.current.isDragging = true;
    }

    if (refCreateNewMarkup.current === true) {
      refCreateNewMarkup.current = false;
    }

    if (mouseType === 2) {
      refNewMarkupPosition.current = cEvent.scenePoint;

      if (canvas) {
        canvas.setCursor('crosshair');
        canvas.renderAll();
      }
    }

    setCanvasMenuContextShown(false);
    clickedMarkupPosition.current = cEvent.viewportPoint;
  };

  const handleContextMenuDrawing = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    // if (canvasMenuContextRef.current) {
    //   canvasMenuContextRef.current.style.position = 'fixed';
    //   canvasMenuContextRef.current.style.left = `${e.clientX}px`;
    //   canvasMenuContextRef.current.style.top = `${e.clientY}px`;
    // }

    // setCanvasMenuContextShown(true);
  };

  // Canculation panning position + boundary zone to pan
  const handleMouseMoveOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:move']) => {
    if (panningInfo.current.isDragging && scale !== canvas?.getZoom()) {
      const {e} = cEvent;
      const vpt = canvas!.viewportTransform;
      const currentPosX = panningInfo.current.x;
      const currentPosY = panningInfo.current.y;
      const currentZoom = canvas!.getZoom();
      let newPosX = (e as MouseEvent).clientX;
      let newPosY = (e as MouseEvent).clientY;
      let newX = vpt[4] + newPosX - currentPosX;
      let newY = vpt[5] + newPosY - currentPosY;
      // Check horizontal boundary
      if (newX > 20) {
        newX = 20;
      } else if (newX < (canvas!.getWidth() * (1 - (currentZoom / scale))) - 20) {
        newX = (canvas!.getWidth() * (1 - (currentZoom / scale))) - 20;
      }

      newPosX = newX + currentPosX - vpt[4];

      // Check vertical boundary
      if (newY > 20) {
        newY = 20;
      } else if (newY < (canvas!.getHeight() * (1 - (currentZoom / scale))) - 20) {
        newY = (canvas!.getHeight() * (1 - (currentZoom / scale))) - 20;
      }

      newPosY = newY + currentPosY - vpt[5];
      vpt[4] = newX;
      vpt[5] = newY;
      canvas!.requestRenderAll();
      panningInfo.current.x = newPosX;
      panningInfo.current.y = newPosY;
    }
  };

  const handleMouseUpClickOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:up']) => {
    if ((cEvent.e as {button?: number}).button === 2) {
      const position = cEvent.scenePoint;
      // Disable menu context if out of box
      if (position.x < 0 || position.x > canvas?.backgroundImage?.width! || position.y < 0 || position.y > canvas?.backgroundImage?.height!) {
        return;
      }

      cEvent.e.preventDefault();
      cEvent.e.stopPropagation();
      cEvent.e.stopImmediatePropagation();
      if (refCreateNewMarkup.current === false) {
        refCreateNewMarkup.current = true;
        if (canvasMenuContextRef.current) {
          canvasMenuContextRef.current.style.position = 'fixed';
          canvasMenuContextRef.current.style.left = `${(cEvent.e as {clientX: number}).clientX}px`;
          canvasMenuContextRef.current.style.top = `${(cEvent.e as {clientY: number}).clientY}px`;
        }

        setCanvasMenuContextShown(true);
      }

      if (canvas) {
        canvas.setCursor('inherit');
        canvas.renderAll();
      }
    }

    if ((cEvent.e as {button?: number}).button === 0) {
      panningInfo.current.isDragging = false;
      if (activeMarkupId && clickedMarkupPosition.current?.eq(cEvent.viewportPoint)) {
        // Open item modal.
        const [itemType, itemIdStr] = activeMarkupId.split('-');
        const itemId = Number(itemIdStr);
        switch (itemType) {
          case 'dmg':
            const damage = damages?.find((item: Partial<Damage>) => item.id === itemId);
            if (damage) {
              setActiveDamage(damage);
            }

            break;
          case 'cml':
            const cml = cmls?.find((item: Partial<Cml>) => item.id === itemId);
            if (cml) {
              setActiveCml(cml);
            }

            break;
          default:
            break;
        }
      }
    }

    clickedMarkupPosition.current = undefined;
  };

  const handleMouseWheelOnFabricCanvas = (cEvent: fabric.CanvasPointerEvents['mouse:wheel']) => {
    const delta = cEvent.e.deltaY;
    console.log(cEvent.e);
    let zoom = canvas!.getZoom();
    const minScaleUp = delta < 0 ? 1.008 : 0.992; // Math.min(0.999 ** delta, 1.05);
    zoom *= minScaleUp;
    const maxScale = Math.max(scale * 4, 16);

    if (zoom > maxScale) {
      zoom = maxScale;
    }

    if (zoom <= scale) {
      zoom = scale;
      resetCanvasTransform();
    } else {
      canvas!.zoomToPoint(new fabric.Point(cEvent.e.offsetX, cEvent.e.offsetY), zoom);
    }

    cEvent.e.preventDefault();
    cEvent.e.stopPropagation();
    const vpt = canvas!.viewportTransform;
    // if (zoom < 0.4) {
    //   vpt[4] = 200 - (viewerSize! * zoom / 2);
    //   vpt[5] = 200 - (viewerSize! * zoom / 2);
    // } else {
    if (vpt[4] > 20) {
      vpt[4] = 20;
    } else if (vpt[4] < (canvas!.getWidth() * (1 - (zoom / scale))) - 20) {
      vpt[4] = (canvas!.getWidth() * (1 - (zoom / scale))) - 20;
    }

    if (vpt[5] > 20) {
      vpt[5] = 20;
    } else if (vpt[5] < (canvas!.getHeight() * (1 - (zoom / scale))) - 20) {
      vpt[5] = (canvas!.getHeight() * (1 - (zoom / scale))) - 20;
    }
    // }
  };

  const handleDblClickOnFabricCanvas = (_cEvent: fabric.CanvasPointerEvents['mouse:dblclick']) => {
    resetCanvasTransform();
  };

  const resetCanvasTransform = () => {
    console.log('reset drawing ', scale);
    canvas?.setViewportTransform([scale, 0, 0, scale, 0, 0]);
  };

  const hideLoading = (callback?: ()=>void, timeInMs?: number) => {
    setTimeout(() => {
      setLoading(false);
      callback?.();
    }, timeInMs ?? 500);
  };

  const showOrAddCmlMarkup = async (item: Partial<Cml>, markupsDisplayed?: boolean, replaced?: boolean) => {
    if (canvas && refCmlValCodesResult.data && !refCmlValCodesResult.loading && !refCmlValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      const markupId = `${CML_PREFIX}${item.id!}`;
      let markups: TMarkupObjects | null = null;
      if (allMarkups && Object.keys(allMarkups).includes(markupId)) {
        markups = allMarkups[markupId];
        if (replaced) {
          canvas.remove(...Object.values(markups));
          markups = null;
        }
      }

      if (!markups) {
        const coordinates = item.coordinates2d ? JSON.parse(item.coordinates2d) : null;
        let markupColor: string = DEFAULT_SHAPE_COLOR;
        let latestEvent: Partial<EventCml> | null | undefined = null;
        if (objectItem && objectItem.__typename === 'Event') {
          latestEvent = eventCmls?.find((evtCml: Partial<EventCml>) => evtCml.cmlId === item.id!);
        } else {
          const latestEventCmlResult = await getLatestEventCml({
            variables: {
              cmlId: item.id!,
            },
            fetchPolicy: 'no-cache',
          });
          latestEvent = latestEventCmlResult.data?.findFirstEventCml as Partial<EventCml>;
        }

        if (latestEvent) {
          const {qualReading} = latestEvent;
          if (qualReading) {
            const codeCond = (refCmlValCodesResult.data.refCmlValCodes as Partial<RefCmlValCode>[]).find((itemValCode: Partial<RefCmlValCode>) => itemValCode.id === qualReading.id)?.codeCond;
            if (codeCond) {
              const shapeColor = (refIntegrityConditionsResult.data.refIntegrityConditions as Partial<RefIntegrityCondition>[]).find((itemInteCond: Partial<RefIntegrityCondition>) => itemInteCond.condition === codeCond)?.color;
              if (typeof shapeColor === 'string') {
                markupColor = shapeColor;
              }
            }
          }
        }

        const position = coordinates && coordinates.x && coordinates.y ? new fabric.Point(coordinates.x, coordinates.y) : new fabric.Point(0, 0);
        const markerCoordinates = item.markerCoordinates2d ? JSON.parse(item.markerCoordinates2d) : null;
        const markerPosition = markerCoordinates && markerCoordinates.x && markerCoordinates.y ? new fabric.Point(markerCoordinates.x, markerCoordinates.y) : new fabric.Point(0, 0);
        // const markerSvg = reactElementToString(<FormIcon size={35} />);

        // Get Icon svg content from icon component
        const markupsProps = {
          markerProps: [
            {
              visible: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              evented: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
            {
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
          ],
          markerGroupProps: {
            fill: markupColor,
            stroke: markupColor,
            ...LOCK_RESIZE_ROTATE,
          },
          tooltipProps: {
            fill: colors.gray[700],
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'Montserrat',
            ...LOCK_RESIZE_ROTATE,
            verticalPosition: EVerticalPosition.MIDDLE,
          },
          positionerProps: {
            fill: 'transparent',
            stroke: 'transparent',
            ...LOCK_RESIZE_ROTATE,
          },
          lineProps: {
            ...LOCK_RESIZE_ROTATE,
          },
        };
        const markerSvg = reactElementToString(<FormIcon size={30} form={item.codeGroup?.shape ?? undefined}/>);
        markups = await createSvgAnnotation(canvas, position, 10, markerSvg, markerPosition, 35, 35, item.position ?? '', {
          props: markupsProps,
          events: {
            onObjectMouseOver() {
              setActiveMarkupId(`${CML_PREFIX}${item.id!}`);
            },
            onObjectMouseOut() {
              setActiveMarkupId(undefined);
            },
            onObjectMoved(e: fabric.ObjectPointerEvents['mouseup:before'], o?: fabric.FabricObject) {
              handleObjectMoved(item, canvas, markups!, lastMarkupPositions, e, o, CML_PREFIX, 'markerCoordinates2d', 'coordinates2d', (id: unknown, field: unknown, value: unknown) => updateCmlDataField(id as number, field as keyof CmlUncheckedUpdateInput, value as (number | string)), updateLastMarkupPosition, markupsProps);
            },
          },
        });
      }

      if (markups) {
        Object.values(markups).forEach((o: TFabricObjectWithPrivate) => {
          o.visible = markupsDisplayed !== false && !!item.display2d;
        });
        addCmlMarkup(item, markups);

        Object.entries(markups).forEach(([key, value]) => {
          updateLastMarkupPosition(lastMarkupPositions, `${CML_PREFIX}${item.id!}`, key as keyof TMarkupObjects, value.getCenterPoint());
        });
      }
    }
  };

  const showOrAddCmlMarkups = async (markupsDisplayed?: boolean) => {
    if (cmls && canvas && refCmlValCodesResult.data && !refCmlValCodesResult.loading && !refCmlValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      for (let i = 0; i < cmls.length; i++) {
        const item: Partial<Cml> = cmls[i];
        await showOrAddCmlMarkup(item, markupsDisplayed);
      }
    }
  };

  const showOrAddDamageMarkup = async (item: Partial<Damage>, markupsDisplayed?: boolean, replaced?: boolean) => {
    // const allMarkups = removeAllDamageMarkups(damages?.map(item => item.id!));
    if (canvas && refDamageValCodesResult.data && !refDamageValCodesResult.loading && !refDamageValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      const markupId = `${DAMAGE_PREFIX}${item.id!}`;
      let markups: TMarkupObjects | null = null;
      if (allMarkups && Object.keys(allMarkups).includes(markupId)) {
        markups = allMarkups[markupId];
        if (replaced) {
          canvas.remove(...Object.values(markups));
          markups = null;
        }
      }

      let qualReading: Partial<RefDamageValCode> | null | undefined = null;
      if (!markups) {
        let markupColor: string = DEFAULT_SHAPE_COLOR;
        if (objectItem && objectItem.__typename === 'Event') {
          qualReading = eventDamages?.find((evtDamage: Partial<EventDamage>) => evtDamage.dmgeId === item.id!)?.qualReading;
        } else if (objectItem && objectItem.__typename === 'Notification') {
          qualReading = notificationDamages?.find((evtDamage: Partial<NotificationDamage>) => evtDamage.dmgeId === item.id!)?.qualReading;
        } else {
          const latestEventDamageResult = await getLatestEventDamage({
            variables: {
              dmgId: item.id!,
            },
            fetchPolicy: 'no-cache',
          });
          qualReading = (latestEventDamageResult.data?.findFirstEventDamage as EventDamage | null)?.qualReading;
        }

        if (qualReading) {
          const codeCond = (refDamageValCodesResult.data!.refDamageValCodes as Partial<RefDamageValCode>[]).find((itemValCode: Partial<RefDamageValCode>) => itemValCode.id === qualReading!.id)?.codeCond;
          if (codeCond) {
            const shapeColor = (refIntegrityConditionsResult.data!.refIntegrityConditions as Partial<RefIntegrityCondition>[]).find((itemInteCond: Partial<RefIntegrityCondition>) => itemInteCond.condition === codeCond)?.color;
            if (typeof shapeColor === 'string') {
              markupColor = shapeColor;
            }
          }
        }

        const markerElemId = Object.keys(EDMGMarkupForm).includes(item.codeGroup?.shape ?? '') ? MARKUP_BACKGROUND_ID : undefined;
        const coordinates = item.coordinates2d ? JSON.parse(item.coordinates2d) : null;
        const position = coordinates && coordinates.x && coordinates.y ? new fabric.Point(coordinates.x, coordinates.y) : new fabric.Point(0, 0);
        const markerCoordinates = item.markerCoordinates2d ? JSON.parse(item.markerCoordinates2d) : null;
        const markerPosition = markerCoordinates && markerCoordinates.x && markerCoordinates.y ? new fabric.Point(markerCoordinates.x, markerCoordinates.y) : new fabric.Point(0, 0);
        // const markerSvg = reactElementToString(<FormIcon size={35} />);

        // Get Icon svg content from icon component
        const markerSvg = reactElementToString(<FormIcon size={30} form={item.codeGroup?.shape ?? undefined}/>);
        const markupsProps = {
          markerElemId,
          markerProps: [
            {
              visible: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              evented: !FIRST_OBJ_INVISIBLE_LIST.includes(item.codeGroup?.shape ?? ''),
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
            {
              fill: markupColor,
              stroke: markupColor,
              ...LOCK_RESIZE_ROTATE,
            },
          ],
          markerGroupProps: {
            fill: markupColor,
            stroke: markupColor,
            ...LOCK_RESIZE_ROTATE,
          },
          tooltipProps: {
            fill: colors.gray[700],
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'Montserrat',
            ...LOCK_RESIZE_ROTATE,
            verticalPosition: EVerticalPosition.BOTTOM,
          },
          positionerProps: {
            fill: 'transparent',
            stroke: 'transparent',
            ...LOCK_RESIZE_ROTATE,
          },
          lineProps: {
            ...LOCK_RESIZE_ROTATE,
          },
        };
        markups = await createSvgAnnotation(canvas!, position, 10, markerSvg, markerPosition, 35, 35, item.position ?? '', {
          props: markupsProps,
          events: {
            onObjectMouseOver() {
              setActiveMarkupId(`${DAMAGE_PREFIX}${item.id!}`);
            },
            onObjectMouseOut() {
              setActiveMarkupId(undefined);
            },
            onObjectMoved(e: fabric.ObjectPointerEvents['mouseup:before'], o?: fabric.FabricObject) {
              handleObjectMoved(item, canvas!, markups!, lastMarkupPositions, e, o, DAMAGE_PREFIX, 'markerCoordinates2d', 'coordinates2d', (id: unknown, field: unknown, value: unknown) => updateDamageDataField(id as number, field as keyof DamageUncheckedUpdateInput, value as (number | string)), updateLastMarkupPosition, markupsProps);
            },
          },
        });
      }

      if (markups) {
        Object.values(markups).forEach((o: TFabricObjectWithPrivate) => {
          o.visible = markupsDisplayed !== false && !!item.display2d;
        });
        addDamageMarkup(item, markups);
        Object.entries(markups).forEach(([key, value]) => {
          updateLastMarkupPosition(lastMarkupPositions, `${DAMAGE_PREFIX}${item.id!}`, key as keyof TMarkupObjects, value.getCenterPoint());
        });
      }
    }
  };

  const showOrAddDamageMarkups = async (markupsDisplayed?: boolean) => {
    if (damages && canvas && refDamageValCodesResult.data && !refDamageValCodesResult.loading && !refDamageValCodesResult.error && refIntegrityConditionsResult.data && !refIntegrityConditionsResult.loading && !refIntegrityConditionsResult.error) {
      for (let i = 0; i < damages.length; i++) {
        const item: Partial<Damage> = damages[i];
        await showOrAddDamageMarkup(item, markupsDisplayed);
      }
    }
  };

  const handleCanvasMenuContextClick = (type: number) => {
    if (type === TAB_CMLS) {
      createNewCmlMarkup(refNewMarkupPosition.current!, idwg, objectItem?.__typename === 'Event');
    } else if (type === TAB_DAMAGES) {
      createNewDamageMarkup(refNewMarkupPosition.current!, idwg);
    }

    setCanvasMenuContextShown(false);
  };

  const calculateFitScaleAndSize = (bgDimension: TDimension, canvasContainer: HTMLDivElement, bgImg?: HTMLImageElement | HTMLCanvasElement) => {
    const bgWidth = bgDimension.width!;
    const bgHeight = bgDimension.height!;
    let newWidth = canvasContainer.clientWidth;
    let newHeight = canvasContainer.clientHeight;
    const bgRatio = bgWidth / bgHeight;
    // const bgRatio = bgImg.width / bgImg.height;
    if (newWidth < newHeight * bgRatio) {
      newHeight = Math.floor(newWidth / bgRatio);
    }

    newWidth = newHeight * bgRatio;
    const bgScale = bgImg ? Math.min(bgWidth / bgImg.width, bgHeight / bgImg.height) : Math.min(newWidth / bgWidth, newHeight / bgHeight);
    return {newWidth, newHeight, bgScale};
  };

  const menuContextItems: TListItem[] = useMemo(() => [
    ...(objectItem?.__typename !== 'Notification' ? [{
      value: TAB_CMLS,
      label: t('label.createCml'),
      disabled: (objectItem?.__typename === 'Event' && config.getActionIsDisabled('event', 'cmlCreate', objectItem.status))
          || !prms.cmls.create,
    }] : []),
    ...(!objectItem ? [] : [{
      value: TAB_DAMAGES,
      label: t('label.createDamage'),
      disabled: (objectItem?.__typename === 'Event' && config.getActionIsDisabled('event', 'damageCreate', objectItem.status))
          || !prms.damages.create,
    }]),
  ], [objectItemId, prms.cmls.create, prms.damages.create]);

  const onBackgroundImgChanged = () => {
    if (storeInit && canvas && canvasContainerRef?.current && viewerSize && (!objectItem || isDrawingsTabSelected)) {
      if (bgImg && bgDimension) {
        // Scale background fit to current width, height
        const {newWidth, newHeight, bgScale} = calculateFitScaleAndSize(bgDimension, canvasContainerRef.current, bgImg);
        // const bgScale = Math.min(newWidth / bgWidth, newHeight / bgHeight);
        // const bgScale = Math.min(newWidth / bgImg.width, newHeight / bgImg.height);
        const backgroundImg = new fabric.FabricImage(bgImg, {
          selectable: false,
          evented: false,
          selection: false,
          scaleX: bgScale,
          scaleY: bgScale,
          imageSmoothing: false,
          objectCaching: false,
          noScaleCache: true,
          // visible: false,
          // resizeFilter: new fabric.filters.Resize({
          //   resizeType: 'lanczos',
          //   scale: bgScale,
          //   // scaleX: bgScale,
          //   // scaleY: bgScale,
          // }),
        });
        // backgroundImg.applyResizeFilters();
        canvas.set('backgroundImage', backgroundImg);
        // viewerRef?.current?.setTransform(0, 0, 1);
        setScale(bgScale);
        setCanvasInfos(canvas, newWidth, newHeight);
        hideLoading(() => {
          setError(null);
          handleScreenFitClick(canvasRef, viewerRef.current);
        });
      } else if (error) {
        hideLoading(() => {
          canvas?.set('backgroundImage', null);
        });
      }
    }
  };

  useEffect(() => {
    if (objectItemId) {
      deleteIdwgStore(storeId ?? `${IDWG_PREFIX}${idwg.id}`);
    }

    setStoreInit(true);
  }, [location, storeId]);

  useEffect(() => {
    if (storeInit) {
      if (!objectItemId || isDrawingsTabSelected) {
        if (selectedTab === TAB_DAMAGES) {
          showOrAddDamageMarkups();
        } else if (!damageMarkupsShown) {
          showOrAddDamageMarkups(false);
        } else {
          showOrAddDamageMarkups();
        }
      }
    }
  }, [damages, canvas, refIntegrityConditionsResult, refDamageValCodesResult, selectedTab, damageMarkupsShown, isDrawingsTabSelected, objectItemId, storeInit]);

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'Event') {
      if (eventFlocCmlFilter === EEventFlocObjectFilter.FLOC) {
        setCmls(filteredEventFlocCmls?.filter((item: Partial<Cml>) => item.idwgId === idwg.id) ?? []);
      } else {
        setCmls((filteredEventCmls?.filter((item: Partial<EventCml>) => item.cml?.idwgId === idwg.id).map((item: Partial<EventCml>) => item.cml) ?? []) as Partial<Cml>[]);
      }
    }
  }, [filteredEventCmls, filteredEventFlocCmls, eventFlocCmlFilter, objectItemId, storeInit]);

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'FunctionalLocation') {
      setCmls(flocCmls?.filter((item: Partial<Cml>) => item.idwgId === idwg.id) ?? []);
    }
  }, [flocCmls, storeInit, objectItemId]);

  useEffect(() => {
    if (!objectItemId && storeInit) {
      setCmls(idwgCmls ?? []);
    }
  }, [idwgCmls, objectItemId, storeInit]);

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'Event') {
      if (eventFlocDamageFilter === EEventFlocObjectFilter.FLOC) {
        setDamages(filteredEventFlocDamages?.filter((item: Partial<Damage>) => item.idwgId === idwg.id) ?? []);
      } else {
        setDamages((filteredEventDamages?.filter((item: Partial<EventDamage>) => item.damage?.idwgId === idwg.id).map((item: Partial<EventDamage>) => item.damage) ?? []) as Partial<Damage>[]);
      }
    }
  }, [filteredEventDamages, eventFlocDamageFilter, filteredEventFlocDamages, objectItemId, storeInit]);

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'Notification') {
      setDamages(notificationDamages?.map((item: Partial<NotificationDamage>) => item.damage as Partial<Damage>)?.filter((item: Partial<Damage>) => item.idwgId === idwg.id) ?? []);
    }
  }, [notificationDamages, objectItemId, storeInit]);

  useEffect(() => {
    if (storeInit && objectItem && objectItem.__typename === 'FunctionalLocation') {
      setDamages(flocDamages?.filter((item: Partial<Damage>) => item.idwgId === idwg.id) ?? []);
    }
  }, [flocDamages, objectItemId, storeInit]);

  useEffect(() => {
    if (!objectItem && storeInit) {
      setDamages(idwgDamages ?? []);
    }
  }, [idwgDamages, objectItemId, storeInit]);

  // On cml / damage tabs changed
  useEffect(() => {
    if (storeInit && (!objectItem || (objectItem.__typename === 'Event' && rightSideTabSelectedValue === EWorkpackRightSideTab.DRAWINGS) || (objectItem.__typename === 'FunctionalLocation' && rightSideTabSelectedValue === EFlocRightSideTab.DRAWINGS))) {
      if (selectedTab === TAB_CMLS) {
        showOrAddCmlMarkups();
      } else if (!cmlMarkupsShown) {
        showOrAddCmlMarkups(false);
      } else {
        showOrAddCmlMarkups();
      }
    }
  }, [cmls, canvas, refCmlValCodesResult, refIntegrityConditionsResult, selectedTab, cmlMarkupsShown, rightSideTabSelectedValue, objectItemId, storeInit]);

  // Update svg src when docName has been changed
  useEffect(() => {
    const src = docName ? `${OptimusClientConfig.current.fileBaseUrl}/${docName}?type=${EDownloadFileType.DRAWING}&contentType=${EDrawingFileContentType.SVG}` : null;
    setSvgSrc(src);
  }, [docName]);

  // Load svg file as background if exists
  useEffect(() => {
    if (storeInit) {
      if (svgSrc) {
        loadBgImg(svgSrc, undefined, true);
      } else if (svgSrc === null) {
        hideLoading(() => {
          canvas?.set('backgroundImage', null);
          setError(t('message.error.fileNotFound'));
        });
      }
    }
  }, [svgSrc, storeInit]);

  // Render canvas on markups changed and ready to show
  useEffect(() => {
    if (canvas && allMarkups && storeInit && (!objectItemId || isDrawingsTabSelected)) {
      hideLoading(() => {
        canvas.renderAll();
      });
    }
  }, [canvas, allMarkups, rightSideTabSelectedValue, objectItemId, storeInit]);

  useEffect(() => {
    changeModalIdwgDetailShown(false);
  }, [rightSideTabSelectedValue]);

  useEffect(() => {
    resetCanvasTransform();
  }, [isSelectedDrawing]);

  useEffect(() => {
    if (storeInit && canvas && isSelectedDrawing) {
      canvas.on('mouse:down', handleMouseDownClickOnFabricCanvas);
      canvas.on('mouse:up', handleMouseUpClickOnFabricCanvas);
      canvas.on('mouse:wheel', handleMouseWheelOnFabricCanvas);
      canvas.on('mouse:dblclick', handleDblClickOnFabricCanvas);
      canvas.on('mouse:move', handleMouseMoveOnFabricCanvas);
    }

    return () => {
      canvas?.off('mouse:down', handleMouseDownClickOnFabricCanvas);
      canvas?.off('mouse:up', handleMouseUpClickOnFabricCanvas);
      canvas?.off('mouse:wheel', handleMouseWheelOnFabricCanvas);
      canvas?.off('mouse:dblclick', handleDblClickOnFabricCanvas);
      canvas?.off('mouse:move', handleMouseMoveOnFabricCanvas);
    };
  }, [canvas, allMarkups, isSelectedDrawing, canvasMenuContextRef, rightSideTabSelectedValue, storeInit, viewerSize]);

  // useEffect(() => {
  //   if (storeInit && objectItem && objectItem.__typename === 'Event') {
  //     setSelectedTab(leftSideTabSelectedValue === EWorkpackLeftSideTab.CMLS ? TAB_CMLS : (leftSideTabSelectedValue === EWorkpackLeftSideTab.DAMAGES ? TAB_DAMAGES : undefined));
  //   }
  // }, [leftSideTabSelectedValue, storeInit]);

  // Init canvas object and load svg file if exists
  useEffect(() => {
    if (storeInit) {
      // setActiveInspectionDrawing(idwg);
      // getIdwgDetail();
      if (!canvasObj.current && canvasContainerRef?.current && canvasRef?.current) {
        const newWidth = canvasContainerRef.current.clientWidth;
        const newHeight = canvasContainerRef.current.clientHeight;
        const cv: fabric.Canvas = new fabric.Canvas(canvasRef.current!, {
          preserveObjectStacking: true,
          enableRetinaScaling: true,
          uniformScaling: true,
          centeredScaling: true,
          width: newWidth,
          height: newHeight,
          selection: false,
          defaultCursor: 'inherit',
          fireRightClick: true,
          stopContextMenu: true,
          imageSmoothingEnabled: true,
          enablePointerEvents: !canvasDisabled,
        });
        canvasObj.current = cv;
        viewerRef?.current?.setTransform(0, 0, 1);
        setCanvasInfos(cv, newWidth, newHeight);
      } else if (canvasObj.current) {
        viewerRef?.current?.setTransform(0, 0, 1);
        // setCanvas(canvasObj.current);
        hideLoading();
      }
    }
  }, [storeInit]);

  useEffect(() => {
    setCanvas(canvasObj.current ?? null);
  }, [canvasObj]);

  useEffect(() => {
    if (objectItem?.__typename === 'Notification') {
      changeCmlMarkupsDisplay(false);
      changeDamageMarkupsDisplay(true);
    } else {
      changeCmlMarkupsDisplay(true);
      changeDamageMarkupsDisplay(true);
    }
  }, [objectItemId]);

  useEffect(() => {
    if (canvas && storeInit) {
      canvas.set({fireRightClick: (!objectItemId || isDrawingsTabSelected) && isSelectedDrawing});
      canvas.renderAll();
    }
  }, [canvas, selectedTab, rightSideTabSelectedValue, isSelectedDrawing, isDrawingsTabSelected, objectItemId, storeInit]);

  // On background changed
  useEffect(() => {
    if (storeInit) {
      onBackgroundImgChanged();
    }
  }, [bgImg, bgDimension, viewerSize, canvas, storeInit]);

  // add loading on drawing file changed
  useEffect(() => {
    if (uploadFile && storeInit && (!objectItem || isDrawingsTabSelected)) {
      // setLoading(true);
      setError(null);
    }
  }, [uploadFile, storeInit, objectItemId]);

  // set object zoom on scale changed
  useEffect(() => {
    if (canvas && storeInit && scale && canvasRef?.current && viewerState && (!objectItem || isDrawingsTabSelected)) {
      const zoomPoint = viewerState.zoomPoint ?? originPoint;
      canvas.zoomToPoint(zoomPoint, scale);
      canvas.getContext().imageSmoothingEnabled = false;
    }
  }, [scale, storeInit]);

  useEffect(() => {
    if (lastCmlUpdated && lastCmlUpdated.idwgId === idwg.id && storeInit && canvas) {
      removeCmlMarkup(lastCmlUpdated);
    }
  }, [lastCmlUpdated, lastCmlUpdatedAt, eventCmls]);

  useEffect(() => {
    if (lastIdwgCmlUpdated && lastIdwgCmlUpdated.idwgId === idwg.id && storeInit && canvas) {
      removeCmlMarkup(lastIdwgCmlUpdated);
    }
  }, [lastIdwgCmlUpdated, lastIdwgCmlUpdatedAt]);

  useEffect(() => {
    if (storeInit && objectItem?.__typename === 'Event' && lastDamageUpdated && lastDamageUpdated.idwgId === idwg.id && canvas) {
      removeDamageMarkup(lastDamageUpdated);
    }
  }, [lastDamageUpdated, lastDamageUpdatedAt]);

  useEffect(() => {
    if (storeInit && objectItem?.__typename === 'Notification' && lastNotifDamageUpdated && lastNotifDamageUpdated.idwgId === idwg.id && canvas) {
      removeDamageMarkup(lastNotifDamageUpdated);
    }
  }, [lastNotifDamageUpdated, lastNotifDamageUpdatedAt]);

  useEffect(() => {
    if (storeInit && lastIdwgDamageUpdated && lastIdwgDamageUpdated.idwgId === idwg.id && !objectItem && canvas) {
      removeDamageMarkup(lastIdwgDamageUpdated);
    }
  }, [lastIdwgDamageUpdated, lastIdwgDamageUpdatedAt]);

  // // Change viewer scale and canvas dimension on zoom change
  useEffect(() => {
    if (storeInit && viewerState && canvas && canvasContainerRef?.current && bgDimension && bgImg && bgDimension.width && bgDimension.height && (!objectItem || isDrawingsTabSelected)) {
      const {newWidth, newHeight, bgScale} = calculateFitScaleAndSize(bgDimension, canvasContainerRef.current);
      // Change scale for canvas
      const newScale: number = bgScale * viewerState.scale;
      if (newScale !== scale) {
        canvas.setDimensions({
          width: newWidth,
          height: newHeight,
        });
        canvas.requestRenderAll();
        setScale(newScale);
      }
    }
  }, [viewerState, storeInit]);

  useEffect(() => {
    if (storeInit) {
      if (objectItem && isDrawingsTabSelected) {
        if (idwgViewerWidth) {
          if (!selectedDrawing) {
            setViewerSize((idwgViewerWidth - 70) / 2);
          } else if (isSelectedDrawing) {
            setViewerSize(idwgViewerWidth - 10);
          } else {
            setViewerSize(0);
          }
        }
      } else if (!objectItem) {
        setViewerSize((size ?? (canvasContainerRef?.current?.clientWidth ? canvasContainerRef!.current!.clientWidth : 0)) - 10);
      }
    }
  }, [idwgViewerWidth, selectedDrawing, isSelectedDrawing, rightSideTabSelectedValue, size, storeInit]);

  useEffect(() => {
    if (storeInit && (!objectItem || isDrawingsTabSelected) && viewerSize) {
      fitToScreen();
    }
  }, [viewerSize, rightSideTabSelectedValue, selectedDrawing, isSelectedDrawing, objectItemId, storeInit]);

  if (!storeInit) {
    return <SpinnerLoader isLoading/>;
  }

  return (
    <StyledViewerContainer
      ref={sortableId ? setNodeRef : undefined}
      className={twMerge(`w-full h-full z-10 overflow-auto relative flex flex-col ${objectItem && !selectedDrawing ? 'border rounded cursor-pointer' : (isSelectedDrawing ? 'selected-drawing' : '')} ${objectItem && selectedDrawing && !isSelectedDrawing ? '!w-0 !h-0' : ''}`)}
      style={objectItem && !selectedDrawing ? {width: viewerSize, height: viewerSize, ...(sortableId ? style : {})} : {}}
    >
      {!isSelectedDrawing && sortableId && <div className={`py-1 flex justify-center opacity-50 hover:opacity-100 ${objectItem?.__typename === 'Event' && !isSelectedDrawing ? ' cursor-move' : ''}`} {...(!isSelectedDrawing && sortableId ? listeners : undefined)}>
        <div className='h-1 rounded-full w-[20%] bg-gray-200 '/>
      </div>}
      {eventIdwg && <Badge className={`absolute top-3 right-2 z-10 ${getInspectionStatusBgColor(eventIdwg.status)} text-white`}>{eventIdwg.status !== null ? t(`label.inspectionStatuses.${eventIdwg.status}`) : ''}</Badge>}
      {modalIdwgDetailShown && <div className='absolute bg-black/10 z-[9] w-full h-full'/>}
      {isSelectedDrawing ? (
        <>
          {hasActionList && <div className='flex justify-start absolute left-3 top-2 z-20 gap-1'>
            {selectedTab !== TAB_CMLS && objectItem?.__typename !== 'Notification' && <ElevatedButton disabled={modalIdwgDetailShown} className={cmlMarkupsShown ? 'bg-secondary text-white' : 'bg-gray-200 text-gray-700'} onClick={handleDisplayCmlClick}>{t('label.displayCmls')}</ElevatedButton> }
            {selectedTab !== TAB_DAMAGES && <ElevatedButton disabled={modalIdwgDetailShown} className={damageMarkupsShown ? 'bg-secondary text-white' : 'bg-gray-200 text-gray-700'} onClick={handleDisplayDamagesClick}>{t('label.displayDamages')}</ElevatedButton>}
          </div>}
          { showIdwgDetailButton
          && <div className='flex justify-end absolute right-3 top-2 z-20'>
            <PrimaryButton shapePreset={Button.ShapePresets.Square} onClick={showIdwgDetail}><FaInfo/></PrimaryButton>
          </div>}
          <InspectionInfo objectItem={objectItem} isShowing={modalIdwgDetailShown} idwg={idwg}/>
          <DeleteIdwgFlocModal idwg={idwg} open={!!flocToDelete}/>
          <DeleteIdwgGridModal idwg={idwg} open={!!gridToDelete}/>
          {deleteInspectionDrawingModalOpen && <DeleteIdwgModal open idwg={idwg}/>}
        </>
      ) : (
        hasActionList && <div className='flex justify-end absolute right-3 top-2 z-20 select-drawing-btn'>
          <PrimaryButton
            shapePreset={Button.ShapePresets.Square} onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedDrawing?.(idwg);
            }}
          ><FaExternalLinkAlt/></PrimaryButton>
        </div>
      )}
      <div
        {...(!isSelectedDrawing && sortableId ? attributes : undefined)}
        className={`h-full w-full ${objectItem && isSelectedDrawing && hasActionList !== false ? 'my-[50px]' : ''} flex-grow ${!isSelectedDrawing && !hasActionList ? 'hover:bg-gray-500 hover:bg-opacity-5' : ''}`}
        onContextMenu={handleContextMenuDrawing}
        onClick={() => setSelectedDrawing?.(idwg)}
      >

        <SpinnerLoaderComponent contentRef={canvasContainerRef} error={!loading && storeInit ? error : null} contentClassName={twMerge(`h-full w-full overflow-hidden ${objectItem && !selectedDrawing ? ' relative' : ''}`, !loading && storeInit && error ? 'max-h-[300px]' : '')} isLoading={loading || !storeInit}>
          <div className={`w-full h-full bg-gray-50 overflow-hidden${canvasDisabled || !isSelectedDrawing ? ' pointer-events-none' : ''}`}><canvas ref={canvasRef}/></div>
        </SpinnerLoaderComponent>
      </div>
      {isSelectedDrawing && !canvasDisabled && menuContextItems.length > 0
      && <div ref={canvasMenuContextRef} className={`${canvasMenuContextShown ? 'w-fit relative' : 'hidden'}`}>
        <span
          className='w-3 h-3 absolute -top-1.5 -left-1.5 border bg-gray-500 rounded-full inline-block' onContextMenuCapture={e => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}/>
        <RadCommand className='shadow'>
          <RadCommandGroup>
            {menuContextItems.map((item: TListItem) => (
              <RadCommandItem key={item.value as number} className='px-3 py-2' disabled={item.disabled} onSelect={() => handleCanvasMenuContextClick(item.value as number)}>
                <LuPlusSquare className='mr-2'/>
                {item.label as string}</RadCommandItem>
            ))}
          </RadCommandGroup>
        </RadCommand>
      </div>}
      {isSelectedDrawing && hasActionList !== false && (
        <div className='flex justify-between absolute w-full bottom-2 z-20 pr-[10px]'>
          <TextButton className='bg-transparent' shapePreset={Button.ShapePresets.Square} disabled={modalIdwgDetailShown} onClick={() => setSelectedDrawing?.(undefined)}><BsFillGrid3X3GapFill size={20} className='text-secondary'/></TextButton>
          <div className='flex items-center gap-1'>
            <PrimaryButton disabled={modalIdwgDetailShown} shapePreset={Button.ShapePresets.Round} onClick={prevSelectedDrawing}><TbPlayerSkipBack size={16}/></PrimaryButton>
            <PrimaryButton disabled={modalIdwgDetailShown} shapePreset={Button.ShapePresets.Round} onClick={nextSelectedDrawing}><TbPlayerSkipForward size={16}/></PrimaryButton>
          </div>
        </div>

      )}
      {!isSelectedDrawing && <div className='text-center flex justify-center p-1 text-2xs font-semibold border-t border-gray-100'>
        <Badge className='bg-blue-50 rounded-md p-1 text-blue-700'>{idwg.idwg}</Badge>
      </div>}
    </StyledViewerContainer>
  );
}
