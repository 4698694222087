import {useQuery} from '@apollo/client';
import {AppMultipleSelector} from '@app/components/Common/Block/MultipleSelector';
import AppSelect from '@app/components/Common/Form/AppSelect';
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper';
import {AssetSite, GetAssetSitesByUserQuery, GetAssetSitesQuery} from '@app/graphql/__types__/graphql';
import {ASSET_SITES_GET_ALL, ASSET_SITES_GET_BY_USER} from '@app/graphql/requests';
import useUsersStore, {UserWithRole} from '@app/stores/users';
import {RadCheckbox, RadInput} from '@holis/react-ui/rad';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

type TForm = {
	readonly user: Partial<UserWithRole>
}

export default function Form({user}: TForm) {
  const {t} = useTranslation();
  const {roles, editingUserRoleId, setEditingUserRoleId, setEditingUserSites, editingUserSites, setEditingUserNotifyNewReport, editingUserNotifyNewReport} = useUsersStore();
  const {data: sitesDatas} = useQuery<GetAssetSitesQuery>(ASSET_SITES_GET_ALL);
  useQuery<GetAssetSitesByUserQuery>(ASSET_SITES_GET_BY_USER, {
    variables: {userId: user.id!},
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setEditingUserSites(data.userSites.map(userSite => ({id: userSite.siteId, site: userSite.site.site})) as AssetSite[]);
    },
  });

  const handleNotifyNewReportChanged = (checked: boolean | 'indeterminate') => {
    setEditingUserNotifyNewReport(checked !== false);
  };

  useEffect(() => {
    // Find current user role
    console.log(user);
    if (user.userRole) {
      setEditingUserRoleId(user.userRole.roleId);
      setEditingUserNotifyNewReport(user.userRole.notifyNewReport ?? false);
    } else {
      const userRole = roles?.find(role => role.usersIds.includes(user.id!));
      if (userRole) {
        setEditingUserRoleId(userRole.id);
      }
    }
  }, [roles, user.id]);

  return (
    <div className='p-4'>
      <div className='grid grid-cols-2 gap-4 mb-6'>
        <InputLabelWrapper isMuted label={t('label.username')}>
          <RadInput disabled variant='holis' value={user.username}/>
        </InputLabelWrapper>

        <InputLabelWrapper isMuted label={t('label.email')}>
          <RadInput disabled variant='holis' value={user.email}/>
        </InputLabelWrapper>

        <InputLabelWrapper isMuted label={t('label.firstName')}>
          <RadInput disabled variant='holis' value={user.firstName}/>
        </InputLabelWrapper>

        <InputLabelWrapper isMuted label={t('label.lastName')}>
          <RadInput disabled variant='holis' value={user.lastName}/>
        </InputLabelWrapper>
        <InputLabelWrapper isMuted label={t('label.notifyNewReport')}>
          <RadCheckbox disabled={!editingUserRoleId ? true : undefined} checked={editingUserNotifyNewReport} onCheckedChange={handleNotifyNewReportChanged}/>
        </InputLabelWrapper>
        <InputLabelWrapper label={t('label.role')}>
          <AppSelect
            items={roles?.map(role => ({label: role.role!, value: role.id!})) ?? []}
            value={editingUserRoleId}
            placeholder={t('label.selectarole')}
            onChange={val => {
              setEditingUserRoleId(Number(val));
              if (!val) {
                setEditingUserSites([]);
                setEditingUserNotifyNewReport(false);
              }
            }}
          />
        </InputLabelWrapper>
      </div>
      <InputLabelWrapper label={t('label.sites')} className='mt-4'>
        <AppMultipleSelector
          disabled={!editingUserRoleId ? true : undefined}
          options={sitesDatas?.assetSites.map(site => ({label: (site as AssetSite).site, value: (site as AssetSite).id.toString()})) ?? []}
          value={editingUserSites?.map(site => ({label: site.site, value: site.id.toString()})) ?? []}
          onChange={val => setEditingUserSites(val.map(v => ({id: Number(v.value), site: v.label})) as AssetSite[])}
        />
      </InputLabelWrapper>
    </div>
  );
}
