import {useLazyQuery} from '@apollo/client';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import PlanModal from '@app/components/Modal/Scheduling/Plan';
import {GetAllPlanViewsQuery, Plan, PlanView} from '@app/graphql/__types__/graphql';
import {SCHEDULING_PLAN_VIEWS_GET_ALL} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import usePlanStore from '@app/stores/plan';
import useSchedulingInspectionPlanStore from '@app/stores/scheduling/inspectionPlan';
import {FORMAT_DATE_EU, OBJ_NEW_ID, ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL} from '@app/utils/constants';
import {ESCHEDULING_PLAN_STATUS} from '@app/utils/enums';
import {generatePathWithBaseUrl, tailwindColorToBgFgStyle} from '@app/utils/functions';
import {RadBadge, RadButton} from '@holis/react-ui/rad';
import moment from 'moment';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {generatePath, useNavigate} from 'react-router-dom';

export default function SchedulingInspectionPlanPage() {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {setActivePlan, creatingPlan} = usePlanStore();
  const {plans, setPlans, setFetchPlans} = useSchedulingInspectionPlanStore();
  const {t} = useTranslation();
  const [getPlansApi, {loading, error}] = useLazyQuery<GetAllPlanViewsQuery>(SCHEDULING_PLAN_VIEWS_GET_ALL);

  const getPlans = () => {
    getPlansApi({
      variables: {
        sites: selectedSites,
        orderBy: [
          {
            id: 'desc',
          },
        ],
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => setPlans(queryResult.data?.planViews as Plan[]));
  };

  const handleNewPlan = () => {
    setActivePlan({
      id: OBJ_NEW_ID,
      status: ESCHEDULING_PLAN_STATUS.INIT,
    }, true);
  };

  useEffect(() => {
    getPlans();
    setFetchPlans(getPlans);
  }, [selectedSites]);

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 60,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
      },
      {
        field: 'plan',
        title: t('label.plan'),
        type: 'string',
        width: 150,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 400,
        ignoreCase: true,
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'string',
        width: 100,
        ignoreCase: true,
      },
      {
        field: 'revision',
        title: t('label.revision'),
        type: 'string',
        width: 60,
      },
      {
        field: 'strategy_strategy',
        title: t('label.strategy'),
        type: 'string',
        width: 100,
        ignoreCase: true,
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'string',
        width: 100,
        ignoreCase: true,
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'string',
        width: 100,
        ignoreCase: true,
      },
      {
        field: 'startDate',
        title: t('label.startDate'),
        type: 'date',
        width: 100,
        cellRenderer: 'startDate',
      },
      {
        field: 'schedulingUnit',
        title: t('label.schedulingUnit'),
        type: 'string',
        width: 100,
        ignoreCase: true,
        hidden: true,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'string',
        width: 100,
        ignoreCase: true,
        cellRenderer: 'status',
      },
      {
        field: 'notes',
        title: t('label.notes'),
        type: 'string',
        width: 200,
        ignoreCase: true,
        hidden: true,
      },
      {
        field: 'floc_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'floc_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'floc_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'floc_techClass_class_class',
        title: t('label.class'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'floc_classSece',
        title: t('label.classSece'),
        type: 'string',
        width: 150,
        ignoreCase: true,
        hidden: true,
      },
      {
        field: 'floc_sector_site_site',
        title: t('label.site'),
        type: 'string',
        width: 100,
        ignoreCase: true,
      },
      {
        field: 'floc_sector_sector',
        title: t('label.sector'),
        type: 'string',
        width: 100,
      },
      {
        field: 'floc_referenceDocument',
        title: t('label.referenceDocument'),
        type: 'string',
        width: 100,
      },
      {
        field: 'floc_grid_grid',
        title: t('label.grid'),
        type: 'string',
        width: 100,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: Partial<Plan>) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={e => {
            const planNumber = rowData.plan;
            if (e.ctrlKey || e.metaKey) {
              window.open(generatePathWithBaseUrl(generatePath(ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL.replace(':number', planNumber!))), '_blank');
              return;
            }

            return navigate(ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL.replace(':number', planNumber!));
          }}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      startDate: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
      status(val: string, rowData: unknown) {
        if (!val) {
          return '';
        }

        const viewDatas = rowData as PlanView;

        return (
          <RadBadge variant='outline' className='py-1 font-normal' style={tailwindColorToBgFgStyle(viewDatas.status_displayColor)}>
            {viewDatas.status_description ?? '-'}
          </RadBadge>
        );
      },
    },
  }), [plans]);

  // @ts-ignore
  const columnsAlwaysVisible: TNestedKeys<Plan>[] = useMemo(() => ([
    'action',
    'plan',
    'description',
    'type_type',
    'status',
    'floc_floc',
  ]), [plans]);

  return (
    <div className='h-full'>
      {creatingPlan && <PlanModal
        isOpen={creatingPlan} onOpenChange={isOpen => {
          if (!isOpen) {
            setActivePlan(undefined);
          }
        }}
      />}

      <Grid
        gridName='scheduling-inspectionPlansGrid'
        queryData={{
          data: plans ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.inspectionPlan')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      >
        <RadButton
          size='sm'
          className='flex gap-1 text-sm'
          onClick={handleNewPlan}
        >
          <LuPlusCircle/> Add plan
        </RadButton>
      </Grid>
    </div>
  );
}
