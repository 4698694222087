import {gql} from '@apollo/client';
import {MEASPOINT_REF_CODE_GROUPS_COMMON_FIELDS} from './refMeasPointCodeGroups';
import {WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS} from './refEventTechniques';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS} from './flocs';
import {MEASPOINT_REF_CLASSES_COMMON_FIELDS} from './refMeasPointClasses';
import {MEASPOINT_REF_CHARS_COMMON_FIELDS} from './refMeasPointChars';

export const INSPECTION_POINTS_COMMON_FIELDS = gql`
    ${MEASPOINT_REF_CODE_GROUPS_COMMON_FIELDS}
    ${MEASPOINT_REF_CLASSES_COMMON_FIELDS}
    ${WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS}
    ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
    ${MEASPOINT_REF_CHARS_COMMON_FIELDS}
    fragment InspectionPointCommonFields on InspectionPoint {
        id
        flocId
        charId
        functionalLocation {
            ...FlocAutocompleteCommonFields
        }
        position
        description
        longDescription
        classId
        class {
            ...RefMeasPointClassCommonFields
        }
        char {
            ...RefMeasPointCharCommonFields
        }
        codeGroupId
        codeGroup {
            ...RefMeasPointCodeGroupCommonFields
        }
        techniqueId
        technique {
            ...RefEventTechniqueCommonFields
        }
        flagStatus
        externalRef
        statusObject {
            status
            description
            displayColor
        }
    }
`;
