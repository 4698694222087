import {useLazyQuery} from '@apollo/client';
import {FlocChar, FunctionalLocation, GetFlocCharsByFlocIdQuery} from '@app/graphql/__types__/graphql';
import {FLOC_CHARS_GET_BY_FLOC_ID} from '@app/graphql/requests';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import GroupingFlocChars from './components/GroupingFlocChars';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';

type TFlocCharsBlock = Readonly<{
  floc: Partial<FunctionalLocation>;
}>;

const headerClassName = 'text-center p-2 bg-gray-100 uppercase text-sm border';

export default function FlocCharsBlock({floc}: TFlocCharsBlock) {
  const [flocChars, setFlocChars] = useState<Array<Partial<FlocChar>>>();
  const [flocCharsByGroup, setFlocCharsByGroup] = useState<Record<string, Array<Partial<FlocChar>>>>({});
  const [getFlocCharsApi, {loading, error}] = useLazyQuery<GetFlocCharsByFlocIdQuery>(FLOC_CHARS_GET_BY_FLOC_ID);
  const {t} = useTranslation();

  const getFlocChars = () => {
    if (floc.id) {
      getFlocCharsApi({
        variables: {
          flocId: floc.id!,
        },
        fetchPolicy: 'no-cache',
      }).then(result => setFlocChars((result.data?.flocChars ?? []) as Array<Partial<FlocChar>>)).catch(() => setFlocChars([]));
    }
  };

  useEffect(() => {
    if (flocChars?.length) {
      setFlocCharsByGroup(_.groupBy(_.sortBy(flocChars, [flocChar => flocChar.characteristic?.group, flocChar => flocChar.characteristic?.characteristic]), flocChar => flocChar.characteristic?.group));
    } else {
      setFlocCharsByGroup({});
    }
  }, [flocChars]);

  useEffect(() => {
    getFlocChars();
  }, [floc]);

  return (
    <div className='grid grid-flow-row-dense grid-cols-3 '>
      <div className={headerClassName}>{t('label.characteristic')}</div>
      <div className={headerClassName}>{t('label.value')}</div>
      <div className={headerClassName}>{t('label.unit')}</div>
      <SpinnerLoaderComponent className='col-span-3 grid grid-cols-3' contentClassName='col-span-3 grid grid-cols-3' isLoading={loading} error={!!error}>
        {Object.keys(flocCharsByGroup).map((group: string) => {
          const groupingFlocChars = flocCharsByGroup[group];
          return (
            <GroupingFlocChars key={group} group={group} flocChars={groupingFlocChars}/>
          );
        })}
      </SpinnerLoaderComponent>
    </div>
  );
}
