import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {Cml, Event, EventCml, GetEventCmlByEvtCmlIdQuery, GetLatestEventCmlByCmlIdQuery, GetLatestPictureByQuery, Notification, Picture, PictureWhereInput} from '@app/graphql/__types__/graphql';
import {PICTURES_GET_LATEST_BY, WORKORDER_EVENT_CMLS_GET_BY_EVT_CML_ID, WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID} from '@app/graphql/requests';
import {useTranslation} from 'react-i18next';
import {LuExternalLink} from 'react-icons/lu';
import {RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem} from '@holis/react-ui/rad';
import useCmlStore from '@app/stores/cml';
import CmlIdwgCard from '../../ItemCard/CmlIdwgCard';
import {QUALREADING_COLOR} from '@app/utils/constants';
import {isValidUnit} from '@app/utils/functions';

type TCmlCard = Readonly<{
    eventCml: Partial<EventCml> | Partial<Cml>;
    event?: Partial<Event>;
    objectItem?: Partial<Event|Notification>;
    className?: string;
    hasMenuContext?: boolean;
    removeItemTitle?: string;
    readonly?: boolean;
    handleRemoveCml?: () => void;
    onDisplay2dChanged?: (newValue?: boolean | null) => void;
    drawingStoreId?: string;
}>

export default function CmlCard({eventCml, className, hasMenuContext, event, onDisplay2dChanged, drawingStoreId}: TCmlCard) {
  const [cml, setCml] = useState<Partial<Cml>>();
  const [evtCml, setEvtCml] = useState<Partial<EventCml> | null>();
  const {setActiveCml} = useCmlStore();
  const [hasPicture, setHasPicture] = useState<boolean>();

  const {t} = useTranslation();
  const [getEventCmlByEvtCmlIdApi] = useLazyQuery<GetEventCmlByEvtCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_BY_EVT_CML_ID);
  const [getLatestEventCmlByCmlIdApi] = useLazyQuery<GetLatestEventCmlByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID);
  const [getLatestPictureApi] = useLazyQuery<GetLatestPictureByQuery>(PICTURES_GET_LATEST_BY);

  const getLatestPic = () => {
    const data: PictureWhereInput = {
      cmlId: {equals: cml!.id!},
    };
    const wrkoId = event?.id;
    if (wrkoId) {
      data.wrkoId = {
        equals: wrkoId,
      };
    }

    return getLatestPictureApi({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      if ((queryResult.data?.findFirstPicture as Picture)?.id) {
        setHasPicture(!!queryResult.data?.findFirstPicture);
      }
    });
  };

  const getEventCml = () => {
    getEventCmlByEvtCmlIdApi({
      variables: {
        evtId: event!.id!,
        cmlId: cml!.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      setEvtCml(result.data?.eventCml as (Partial<EventCml> | undefined));
    });
  };

  const getLatestEventCml = () => {
    getLatestEventCmlByCmlIdApi({
      variables: {
        cmlId: cml!.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      setEvtCml(result.data?.findFirstEventCml as (Partial<EventCml> | undefined));
    });
  };

  useEffect(() => {
    if (!evtCml && !!cml) {
      if (event) {
        getEventCml();
      } else {
        getLatestEventCml();
      }
    }
  }, [evtCml]);

  useEffect(() => {
    setCml((eventCml as EventCml).cml ?? eventCml as Partial<Cml>);
    setEvtCml((eventCml as EventCml).cml ? eventCml as Partial<EventCml> : null);
  }, [eventCml]);

  useEffect(() => {
    if (cml?.id) {
      getLatestPic();
    }
  }, [cml]);

  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <CmlIdwgCard
          className={className}
          drawingStoreId={drawingStoreId}
          id={cml?.id}
          idwgId={cml?.idwgId!}
          hasPicture={hasPicture}
          display2d={cml?.display2d}
          class={cml?.codeGroup?.class?.class}
          position={cml?.position}
          title={[`${cml?.diameter ? `${cml?.diameter} ''` : ''} ${cml?.part?.part ?? ''}`.trim(), cml?.codeGroup?.codeGroup ?? ''].filter(Boolean).join(' - ').toUpperCase() || '-'}
          description={cml?.description ?? '-'}
          technique={cml?.technique?.technique}
          floc={cml?.functionalLocation?.floc}
          measurementColor={(evtCml?.qualReading?.integrityCondition.color ?? QUALREADING_COLOR) as string}
          measurementValue={(isValidUnit(cml?.codeGroup?.unit) ? evtCml?.quantReading : evtCml?.qualReading?.valCode) ?? '-'}
          onDisplay2dChanged={onDisplay2dChanged}/>
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveCml(cml!)}>
          <LuExternalLink className='mr-2'/> {t('label.open')}
        </RadContextMenuItem>
        {/* {!!handleRemoveCml && <RadContextMenuItem disabled={readonly} className='item-destructive' onClick={handleRemoveCml}>
          <LuTrash2 className='mr-2'/> {removeItemTitle ?? t('label.removeFromEvent')}
        </RadContextMenuItem>} */}
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
