import {gql} from '@apollo/client';
import {WORKORDER_EVENT_VIEWS_COMMON_FIELDS, WORKORDER_EVENTS_COUNTER_FIELDS, WORKORDER_EVENTS_DETAIL_FIELDS} from '../fragments';

export const WORKORDER_EVENTS_GET_ALL_BY_STAGE = gql`
  ${WORKORDER_EVENTS_DETAIL_FIELDS}
  query getEventsByStage($stage: String, $orderBy: [EventOrderByWithRelationInput!]) {
    events(where: {status: {startsWith: $stage}}, orderBy: $orderBy) {
      ...EventDetailFields
    }
  }
`;

export const WORKORDER_EVENT_VIEWS_GET_ALL_BY_STAGE = gql`
  ${WORKORDER_EVENT_VIEWS_COMMON_FIELDS}
  query getEventViewsByStage($stage: String, $sites: [String!], $orderBy: [EventViewOrderByWithRelationInput!]) {
    eventViews(where: {stage: {equals: $stage}, schedulingTag_sector_site_site:  {
       in: $sites
    }}, orderBy: $orderBy) {
      ...EventViewCommonFields
    }
  }
`;

export const WORKORDER_EVENTS_GET_BY_PLAN_ID = gql`
${WORKORDER_EVENTS_DETAIL_FIELDS}
query getEventsByPlanId($planId: Int, $orderBy: [EventOrderByWithRelationInput!]) {
  events(where: {planId: {equals: $planId}}, orderBy: $orderBy) {
    ...EventDetailFields
  }
}
`;

export const WORKORDER_EVENTS_GET_BY_FLOC_ID = gql`
${WORKORDER_EVENTS_DETAIL_FIELDS}
query getEventsByFlocId($flocId: Int!, $orderBy: [EventOrderByWithRelationInput!]) {
  events(where: {eventFlocs: {some: { flocId: {equals: $flocId}}}}, orderBy: $orderBy) {
    ...EventDetailFields
  }
}
`;

export const WORKORDER_EVENTS_UPDATE_BY_ID = gql`
${WORKORDER_EVENTS_DETAIL_FIELDS}
mutation UpdateEventById($id: Int!, $data: EventUncheckedUpdateInput!) {
  updateOneEvent(data: $data, where: {id: $id}) {
    ...EventDetailFields
  }
}`;

export const WORKORDER_EVENTS_DELETE_BY_ID = gql`
mutation DeleteEventById($id: Int!) {
  deleteOneEvent(where: {id: $id}) {
    id
  }
}`;

export const WORKORDER_EVENTS_GET_DETAIL_BY_ID = gql`
${WORKORDER_EVENTS_DETAIL_FIELDS}
${WORKORDER_EVENTS_COUNTER_FIELDS}
query getEventDetailById($id: Int!) {
  event(where: {id: $id}) {
    ...EventDetailFields
    ...EventCounterFields
  }
}`;

export const WORKORDER_EVENTS_GET_DETAIL_BY_NUMBER = gql`
${WORKORDER_EVENTS_DETAIL_FIELDS}
${WORKORDER_EVENTS_COUNTER_FIELDS}
query getEventDetailByNumber($number: String!) {
  event(where: {event: $number}) {
    ...EventDetailFields
    ...EventCounterFields
  }
}`;

export const WORKORDER_EVENTS_GET_COUNTERS_BY_ID = gql`
query getEventCountersById($id: Int!) {
  ${WORKORDER_EVENTS_COUNTER_FIELDS}
  event(where: {id: $id}) {
    ...EventCounterFields
  }
}`;

export const WORKORDER_EVENTS_GENERATE_WORKPACK_BY_ID = gql`
mutation generateEventWorkpackById($id: Int!) {
  generateWorkpack(input: {id: $id})
}`;

export const WORKORDER_EVENTS_CALCULATE_INTEGRITY_STATUS_BY_ID = gql`
mutation calculateEventIntegrityStatusById($id: Int!) {
  calculateIntegrityStatus(input: {id: $id})
}`;

export const WORKORDER_EVENTS_GET_COUNTER = gql`
query GetWorkorderEventsCounter {
  aggregateEvent {
    _count {
      id
    }
  }
}
`;

export const WORKORDER_GET_EXPORT_STATUS = gql`
query GetWorkorderExportStatus($id: Int!) {
  event(where: {id: $id}) {
    statusExport
  }
}`;
