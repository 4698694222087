import {gql} from '@apollo/client';

export const DAMAGE_REF_CODE_GROUPS_COMMON_FIELDS = gql`
  fragment RefDamageCodeGroupCommonFields on RefDamageCodeGroup {
    id
    codeGroup
    description
    defaultDesc
    shape
    classId
    class {
      id
      class
      description
    }
    codeGroupValCodes {
      valCodeId
    }
    defaultTechniqueId
}`;
