import {useLazyQuery} from '@apollo/client';
import NotificationNewModal from '@app/components/Common/Block/Notification/NotificationNewModal';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import {GetAllNotificationViewsQuery, Notification, NotificationView} from '@app/graphql/__types__/graphql';
import {NOTIFICATION_VIEWS_GET_ALL} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import useNotificationStore from '@app/stores/notification';
import {ExportStatusFromJsonSteps} from '@app/types/export-datas-types';
import {FORMAT_DATE_EU, GRID_DEFAULT_LAYOUT_IAN, OBJ_NEW_ID, ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL} from '@app/utils/constants';
import {ENOTIFICATION_NOTIF_STATUS} from '@app/utils/enums';
import {generatePathWithBaseUrl, tailwindColorToBgFgStyle} from '@app/utils/functions';
import {ensureDefaultGridLayout} from '@app/utils/functions/layout';
import {RadBadge, RadButton, RadCheckbox} from '@holis/react-ui/rad';
import moment from 'moment';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {MouseEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {generatePath, useNavigate} from 'react-router-dom';

const GRID_NAME = 'anomalyManagement-notificationsGrid';

export default function AnomalyManagementNotificationPage() {
  const {setActiveNotification, activeNotification} = useNotificationStore();
  const [notifications, setNotifications] = useState<Partial<Notification>[]>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const [getNotificationsApi, {loading, error}] = useLazyQuery<GetAllNotificationViewsQuery>(NOTIFICATION_VIEWS_GET_ALL);

  // const handleCloseModal = () => {
  //   setActiveNotification();
  // };

  const getNotifications = () => {
    if (selectedSites?.length) {
      getNotificationsApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setNotifications(queryResults.data?.notificationViews as Partial<Notification>[]));
    } else {
      setNotifications([]);
    }
  };

  const openNotifDetail = (notifNumber: string, e: MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(generatePathWithBaseUrl(generatePath(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notifNumber))), '_blank');
      return;
    }

    navigate(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notifNumber));
  };

  const handleNewNotif = () => {
    setActiveNotification({
      id: OBJ_NEW_ID,
      status: ENOTIFICATION_NOTIF_STATUS.INIT,
      notif: t('label.autoSetValue'),
      creationDate: new Date(),
      __typename: 'Notification',
    });
  };

  const handleNewNotifCreated = (notification: Partial<Notification>) => {
    navigate(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notification.notif!));
  };

  useEffect(() => {
    getNotifications();
  }, [selectedSites]);

  const gridProps: TGridProps = {
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 60,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
      },
      {
        field: 'notif',
        title: t('label.notificationNumber'),
        type: 'string',
        width: 80,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 80,
      },
      {
        field: 'type_notifType',
        title: t('label.type'),
        type: 'string',
        width: 80,
      },
      {
        field: 'creationDate',
        title: t('label.creationDate'),
        type: 'date',
        cellRenderer: 'date',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportedBy',
        title: t('label.reportedBy'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'approvedBy',
        title: t('label.approvedBy'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'isTempRepair',
        title: t('label.tempRepair'),
        type: 'string',
        fieldType: 'boolean',
        cellRenderer: 'boolean',
        width: 80,
      },
      {
        field: 'ffsRequired',
        title: t('label.ffsRequired'),
        type: 'string',
        width: 80,
        cellRenderer: 'boolean',
        fieldType: 'boolean',
      },
      {
        field: 'isBelowMawt',
        title: t('label.belowMAWT'),
        type: 'string',
        width: 80,
        fieldType: 'boolean',
        cellRenderer: 'boolean',
      },
      {
        field: 'isLeak',
        title: t('label.leak'),
        type: 'string',
        width: 80,
        fieldType: 'boolean',
        cellRenderer: 'boolean',
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'string',
        width: 80,
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'string',
        width: 80,
      },
      {
        field: 'plantWorkCenter_code',
        title: t('label.plantWorkCenter'),
        type: 'string',
        width: 80,
      },
      {
        field: 'shutdown_code',
        title: t('label.shutdown'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'jobType_code',
        title: t('label.jobType'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'ressource_code',
        title: t('label.ressource'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'repairSystem_code',
        title: t('label.repairSystem'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'detection_code',
        title: t('label.detection'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'consequence',
        title: t('label.consequence'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'severity',
        title: t('label.severity'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'likelihood_likelihood',
        title: t('label.likelihood'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'priority_code',
        title: t('label.priority'),
        type: 'string',
        width: 80,
      },
      {
        field: 'requiredStartDate',
        title: t('label.requiredStartDate'),
        type: 'date',
        cellRenderer: 'date',
        width: 80,
      },
      {
        field: 'requiredEndDate',
        title: t('label.requiredEndDate'),
        type: 'date',
        cellRenderer: 'date',
        width: 80,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'string',
        width: 80,
        cellRenderer: 'status',
      },
      {
        field: 'externalId',
        title: t('label.externalId'),
        type: 'string',
        width: 80,
      },
      {
        field: 'externalStatus',
        title: t('label.externalStatus'),
        type: 'string',
        width: 80,
      },
      {
        field: 'event_event',
        title: t('label.workorder'),
        type: 'string',
        width: 80,
      },
      {
        field: 'schedulingTag_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'schedulingTag_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'schedulingTag_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'schedulingTag_techClass_class_class',
        title: t('label.class'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'schedulingTag_classSece',
        title: t('label.classSece'),
        type: 'string',
        width: 150,
        ignoreCase: true,
        hidden: true,
      },
      {
        field: 'schedulingTag_sector_site_site',
        title: t('label.site'),
        type: 'string',
        width: 100,
        ignoreCase: true,
      },
      {
        field: 'schedulingTag_sector_sector',
        title: t('label.sector'),
        type: 'string',
        width: 100,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: unknown) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={e => openNotifDetail((rowData as Partial<Notification>).notif!, e)}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      status(val: string, rowData: unknown) {
        if (!val) {
          return '';
        }

        const notif = rowData as Partial<NotificationView>;

        return (
          <RadBadge variant='outline' className='py-1 font-normal' style={tailwindColorToBgFgStyle(notif.status_displayColor)}>
            {notif.status_description ?? '-'}
          </RadBadge>
        );
      },
      statusExport(val: string) {
        const st = ExportStatusFromJsonSteps(val);
        return st.status.toUpperCase();
      },
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
      boolean: (val: boolean) => <><RadCheckbox title={t(`label.${val ? 'yes' : 'no'}`)} checked={!!val}/><span className='hidden'>{t(`label.${val ? 'yes' : 'no'}`)}</span></>,
    },
  };

  // @ts-ignore
  const columnsAlwaysVisible: TNestedKeys<Notification>[] = [
    'action',
    'notif',
    'description',
    'type.notifType',
    'status',
    'externalId',
    'functionalLocation.floc',
  ];

  useEffect(() => {
    ensureDefaultGridLayout(GRID_NAME, GRID_DEFAULT_LAYOUT_IAN);
  }, []);

  return (
    <div className='h-full'>
      <Grid
        gridName={GRID_NAME}
        queryData={{
          data: notifications ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.notification')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      >
        <RadButton
          size='sm'
          className='flex gap-1 text-sm bg-sky-500 text-white hover:bg-sky-600'
          onClick={handleNewNotif}
        >
          <LuPlusCircle/> {t('label.addNotification')}
        </RadButton>
      </Grid>

      {!!activeNotification && <NotificationNewModal isOpen onCreated={handleNewNotifCreated} onOpenChange={isOpen => !isOpen && setActiveNotification()}/>}
    </div>
  );
}
