import {gql} from '@apollo/client';
import {FLOCS_DETAIL_COMMON_FIELDS} from './flocs';
import {WORKORDER_REF_EVENT_TYPES_COMMON_FIELDS} from './refEventTypes';
import {FLOC_CATALOGS_COMMON_FIELDS} from './refFlocCatalogs';
import {APP_EVENT_STATUSES_COMMON_FIELDS} from './eventStatuses';
import {SCHEDULING_PLANS_COMMON_FIELDS} from './plans';

export const WORKORDER_EVENTS_COMMON_FIELDS = gql`
    fragment EventCommonFields on Event {
        id
        event
        description
        longDescription
        status
        notes
        inspectorId
        reviewerId
        approverId
        reportNumber
        reportDescription
        reportSummary
        reportingDate
        inspectionDate
        inspectionInProgress
        isRejected
        statusExport
        planId
        schedulingTagId
        typeId
        priority
        batchNumber
        plannedDate
        releasedDate
        plannerGroupId
        mainWorkCenterId
        isPublished
    }
`;

export const WORKORDER_EVENTS_DETAIL_FIELDS = gql`
  ${WORKORDER_EVENTS_COMMON_FIELDS}
  ${FLOCS_DETAIL_COMMON_FIELDS}
  ${WORKORDER_REF_EVENT_TYPES_COMMON_FIELDS}
  ${FLOC_CATALOGS_COMMON_FIELDS}
  ${APP_EVENT_STATUSES_COMMON_FIELDS}
  ${SCHEDULING_PLANS_COMMON_FIELDS}
  fragment EventDetailFields on Event {
    ...EventCommonFields
    schedulingTag {
      ...FlocDetailFields
    }
    type {
      ...RefEventTypeCommonFields
    }
    plannerGroup {
      ...RefFlocCatalogsCommonFields
    }
    mainWorkCenter {
      ...RefFlocCatalogsCommonFields
    }
    statusWorkOrder {
      ...EventStatusCommonFields
    }
    plan {
      ...PlanCommonFields
    }
  }
`;

export const WORKORDER_EVENTS_COUNTER_FIELDS = gql`
  fragment EventCounterFields on Event {
    _count {
      eventTasks
      eventFlocs
      eventCmls
      eventDocuments
      notifications
      eventDamages
      eventInspectionPoints
    }
  }
`;

export const WORKORDER_EVENT_VIEWS_COMMON_FIELDS = gql`
  fragment EventViewCommonFields on EventView {
    id
    event
    description
    longDescription
    status
    notes
    inspectorId
    reviewerId
    approverId
    reportNumber
    reportDescription
    reportSummary
    reportingDate
    inspectionDate
    inspectionInProgress
    isRejected
    statusExport
    planId
    schedulingTagId
    typeId
    priority
    batchNumber
    plannedDate
    releasedDate
    plannerGroupId
    mainWorkCenterId
    schedulingTag_floc
    schedulingTag_description
    schedulingTag_classSece
    schedulingTag_techClass_techClass
    schedulingTag_techClass_description
    schedulingTag_techClass_class_description
    schedulingTag_techClass_class_class
    schedulingTag_plannerGroup_category
    schedulingTag_plannerGroup_code
    schedulingTag_plannerGroup_description
    schedulingTag_mainWorkCenter_category
    schedulingTag_mainWorkCenter_code
    schedulingTag_mainWorkCenter_description
    schedulingTag_sector_id
    schedulingTag_sector_sector
    schedulingTag_sector_description
    schedulingTag_sector_site_id
    schedulingTag_sector_site_site
    schedulingTag_sector_site_description
    schedulingTag_sector_site_longDescription
    type_type
    type_description
    grid_grid
    grid_description
    stage
    status_displayColor
    status_workflow
    status_description
    isPublished
  }
`;
