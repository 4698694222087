import Actions from '@app/components/Common/Block/Cml/CmlModal/components/Actions';
import GeneralInfo from '@app/components/Common/Block/Cml/CmlModal/components/GeneralInfo';
import PictureCarousel from '@app/components/Common/Block/Cml/CmlModal/components/pictures/PictureCarousel';
import MultiplePanelsModal, {TMultiplePanelsModal} from '@app/components/Modal/NewMultiplePanels';
import {Cml, Event, EventCml, Picture, UpdateCmlByIdMutation, UpdateEventCmlByIdMutation} from '@app/graphql/__types__/graphql';
import useCmlStore from '@app/stores/cml';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {EApiOperator} from '@app/utils/enums';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {TCarouselApi} from '@holis/react-ui';
import {useRef, useState} from 'react';
import DeleteCmlModal from './components/DeleteCmlModal';
import EventCmlHistory from './components/events/EventCmlsHistory';
import Footer from './components/Footer';
import Header from './components/Header';
import MeasurementBlock from './components/measurement';
import {CMLS_UPDATE_BY_ID, WORKORDER_EVENT_CMLS_UPDATE_BY_ID} from '@app/graphql/requests';
import {useMutation} from '@apollo/client';
import {useHolisAuth} from '@holis/auth-client-react';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
type TCmlModal = TMultiplePanelsModal<Partial<Cml>> & Readonly<{
    event?: Partial<Event>;
    eventCml?: Partial<EventCml>;
    onCreatedOrUpdated?: (cml?: Partial<Cml>, operator?: EApiOperator) => void;
    onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
  }>

export default function CmlModal({item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventCml, ...restProps}: TCmlModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID;
  const prms = useUserPermissions();
  const {t} = useTranslation();
  const {user} = useHolisAuth();
  const {deleteCmlModalOpen, resetData, changeDeleteCmlModalDisplay, editCml, setActiveCml} = useCmlStore();
  const cmlUpdateDatas = useRef<Partial<Cml>>({});
  const evtCmlUpdateDatas = useRef<Partial<EventCml>>({});
  const [itemIsSaved, setItemIsSaved] = useState<boolean>(true);
  const [isModalOpened, setIsModalOpened] = useState(true);

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>();
  const [updateCmlApi] = useMutation<UpdateCmlByIdMutation>(CMLS_UPDATE_BY_ID);
  const [updateEventCmlApi] = useMutation<UpdateEventCmlByIdMutation>(WORKORDER_EVENT_CMLS_UPDATE_BY_ID);

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api);
  };

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination();
  };

  const handleCloseModal = () => {
    resetData();
    onOpenChange?.(false);
  };

  const handleCmlDeleted = () => {
    handleCloseModal();
    onCreatedOrUpdated?.(item);
  };

  const handleFieldUpdated = (imeType: 'cml' | 'meas', field: string, value: unknown) => {
    if (['quantReading', 'qualReadingId'].includes(field)) {
      return;
    }

    if ((value as { id: string })?.id) {
      value = (value as { id: string }).id;
    }

    if (imeType === 'cml') {
      cmlUpdateDatas.current = {...cmlUpdateDatas.current, [field]: value};
    } else {
      evtCmlUpdateDatas.current = {...evtCmlUpdateDatas.current, [field]: value};
    }

    setItemIsSaved(false);
  };

  const saveMeasurement = async (qualReadingId: number, quantReading: unknown) => {
    console.log('Saving measurement', qualReadingId, quantReading);
    const r = await updateEventCmlApi({
      variables: {
        id: eventCml!.id,
        data: {
          qualReadingId: {set: qualReadingId},
          reader: {set: user?.username},
          reportingDate: {set: new Date()},
          quantReading: {set: quantReading ?? null},
        },
      },
    });
    setActiveCml((r.data?.updateOneEventCml as Partial<EventCml>).cml!);
    onCreatedOrUpdated?.(item, isNew ? EApiOperator.CREATE : EApiOperator.UPDATE);
  };

  const saveAlarms = async () => {
    const r = await updateCmlApi({
      variables: {
        id: item.id,
        data: {
          nominal: cmlUpdateDatas.current.nominal ? {set: cmlUpdateDatas.current.nominal} : undefined,
          alarm1: cmlUpdateDatas.current.alarm1 ? {set: cmlUpdateDatas.current.alarm1} : undefined,
          alarm2: cmlUpdateDatas.current.alarm2 ? {set: cmlUpdateDatas.current.alarm2} : undefined,
          alarm3: cmlUpdateDatas.current.alarm3 ? {set: cmlUpdateDatas.current.alarm3} : undefined,
        },
      },
    });
    setActiveCml((r.data?.updateOneCml as Partial<Cml>));
  };

  const handleSaveClick = async () => {
    const cmlUpdateRequest: Record<string, unknown> = {};
    const evtCmlUpdateRequest: Record<string, unknown> = {};

    Object.keys(cmlUpdateDatas.current).forEach(key => {
      if (key === 'classId') {
        return;
      }

      cmlUpdateRequest[key] = {set: cmlUpdateDatas.current[key as keyof Cml] ?? null};
    });
    Object.keys(evtCmlUpdateDatas.current).forEach(key => {
      evtCmlUpdateRequest[key] = {set: evtCmlUpdateDatas.current[key as keyof EventCml] ?? null};
    });

    try {
      if (Object.keys(cmlUpdateDatas.current).length) {
        await updateCmlApi({variables: {id: item.id, data: cmlUpdateRequest}});
      }

      if (Object.keys(evtCmlUpdateDatas.current).length) {
        evtCmlUpdateRequest.reader = {set: user?.username};
        evtCmlUpdateRequest.reportingDate = {set: new Date()};
        await updateEventCmlApi({variables: {id: eventCml?.id, data: evtCmlUpdateRequest}});
      }

      cmlUpdateDatas.current = {};
      evtCmlUpdateDatas.current = {};
      setItemIsSaved(true);
      AppNotifications.success(t('message.success.eventCmlUpdated'));
      onCreatedOrUpdated?.(item, isNew ? EApiOperator.CREATE : EApiOperator.UPDATE);
      handleCloseModal();
    } catch {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  return (
    <MultiplePanelsModal
      autoSaveId='modal-cml'
      header={<Header actions={isNew ? null : <Actions cml={item} deleteDisabled={!prms.cmls.delete} onCreatedOrUpdated={onCreatedOrUpdated}/>} cml={item}/>}
      footer={<Footer cml={item} saveButtonEnabled={!itemIsSaved} onSaveClick={handleSaveClick} onCancelClick={() => setIsModalOpened(false)}/>}
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={() => itemIsSaved}
      onOpenChange={opened => {
        if (opened) {
          setIsModalOpened(true);
        } else {
          handleCloseModal();
        }
      }}
      {...restProps}
      isOpen={isModalOpened}
    >
      <GeneralInfo cml={item} readonly={!prms.cmls.update} onFieldUpdated={(f, v) => handleFieldUpdated('cml', f, v)} onAlarmChanged={saveAlarms}/>
      <div className='flex flex-col w-full overflow-auto'>
        {!!eventCml && <MeasurementBlock event={event!} readonly={!prms.cmls.measurement} cml={item} eventCml={eventCml} onFieldUpdated={(f, v) => handleFieldUpdated('meas', f, v)} onMeasurementReadingUpdated={saveMeasurement}/>}
        <EventCmlHistory event={event} cml={editCml!} eventCml={eventCml} onCreatedOrUpdated={onCreatedOrUpdated}/>
        <PictureCarousel readonly={!prms.cmls.update && !prms.cmls.measurement} cml={item} event={event} onCarouselRender={handlePictureCarouselRender} onChanged={onPicturesChanged}/>
        <DeleteCmlModal open={deleteCmlModalOpen} cml={item} changeDeleteCmlModalDisplay={changeDeleteCmlModalDisplay} onCmlDeleted={handleCmlDeleted}/>
      </div>
    </MultiplePanelsModal>
  );
}
