
export const APP_QUERY_ACCESS_TOKEN = 'access_token';
export const OBJ_NEW_ID = -1;
export const NO_DRAWING_ID = -1;
export const pictureCategories = ['general', 'inspectionPoint', 'damage', 'cml'];
export const USER_SELECTED_SITES = 'user_selected_sites';
export const MARKUP_BACKGROUND_ID = 'MARKUP_BACKGROUND';
export const GRID_DEFAULT_LAYOUT_DWG = '{"state":{"treeState":{"columns":{"action":{"filter":false,"width":75},"id":{"hidden":true,"width":106},"sector_site_site":{"width":100},"sector_sector":{"width":105},"idwg":{"sort":"asc","width":250},"description":{"width":461},"type_type":{"width":135},"revision":{"width":115},"flagStatus":{"hidden":true,"width":170},"docName":{"width":400}},"scrollTop":100,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_DOC = '{"state":{"treeState":{"columns":{"action":{"filter":false,"width":91},"id":{"hidden":true,"width":80},"sector_site_site":{"width":109},"sector_sector":{"width":97},"document":{"width":261},"description":{"width":975},"type_code":{"width":103},"discipline_code":{"width":108},"catalog_code":{"hidden":true,"width":122},"phase_code":{"width":117},"stage_code":{"width":110},"docName":{"width":279},"revision":{"width":155},"originator_code":{"hidden":true,"width":80},"flagStatus":{"hidden":true,"width":80}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_IAN = '{"state":{"treeState":{"columns":{"externalStatus":{"hidden":true,"width":100},"action":{"filter":false,"width":60},"schedulingTag_sector_site_site":{"pinned":false,"width":100},"status":{"sort":"asc","pinned":false,"width":146},"notif":{"width":145},"externalId":{"pinned":false,"width":174},"type_notifType":{"pinned":false,"width":80},"schedulingTag_floc":{"pinned":false,"width":389},"description":{"width":354},"schedulingTag_techClass_techClass":{"pinned":false,"width":150},"creationDate":{"hidden":false,"width":127},"reportedBy":{"hidden":false,"width":129},"approvedBy":{"hidden":false,"width":123},"isTempRepair":{"width":127},"ffsRequired":{"width":111},"isBelowMawt":{"width":98},"isLeak":{"width":80},"plannerGroup_code":{"width":121},"mainWorkCenter_code":{"width":165},"plantWorkCenter_code":{"width":150},"consequence":{"hidden":false,"pinned":false,"width":125},"priority_code":{"width":80},"requiredEndDate":{"width":164},"event_event":{"width":164},"id":{"hidden":true,"width":80},"shutdown_code":{"hidden":true,"width":80},"jobType_code":{"hidden":true,"width":80},"ressource_code":{"hidden":true,"width":80},"repairSystem_code":{"hidden":true,"width":80},"detection_code":{"hidden":true,"width":80},"severity":{"hidden":true,"width":80},"likelihood_likelihood":{"hidden":true,"width":80},"requiredStartDate":{"hidden":true,"width":80},"statusExport":{"hidden":true,"width":80},"schedulingTag_description":{"hidden":true,"width":150},"schedulingTag_techClass_class_class":{"hidden":true,"width":150},"schedulingTag_classSece":{"hidden":true,"width":150},"schedulingTag_sector_sector":{"hidden":true,"width":100}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
