import {useLazyQuery, useQuery} from '@apollo/client';
import AppCombobox from '@app/components/Common/Form/AppCombobox';
import AppSelect from '@app/components/Common/Form/AppSelect';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper';
import {AppListItem} from '@app/components/Common/Form/list-item';
import {GetAllFlocsAutocompleteQuery, FunctionalLocation, GetAllRefCmlCodeGroupsQuery, GetAllRefEventTechniquesQuery, GetAllRefFlocCatalogsQuery, GetAllRefFlocPartsQuery, RefCmlCodeGroup, GetFlocsByIdwgIdQuery, RefFlocCatalogs, RefFlocPart, RefEventTechnique, GetGridsByIdwgIdQuery, GetGridsBySectorIdQuery, Grid} from '@app/graphql/__types__/graphql';
import {CML_REF_CODE_GROUPS_GET_MANY, FLOC_REF_PARTS_GET_MANY, FLOCS_GET_ALL_AUTOCOMPLETE, FLOCS_GET_BY_IDWG_ID, GRIDS_GET_BY_IDWG_ID, GRIDS_GET_BY_SECTOR_ID, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY} from '@app/graphql/requests';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';
import useCmlStore from '@app/stores/cml';
import {MAX_LENGTH_VALIDATORS} from '@app/stores/cml';
import {EFLOC_CATALOGS_CATEGORY} from '@app/utils/enums';
import {isStringNotEmpty} from '@app/utils/functions';
import {RadFormField, RadInput, RadTextarea} from '@holis/react-ui/rad';
import {t} from 'i18next';
import {useEffect, useState} from 'react';

export type TForm = Readonly<{
  setFormValue?: (name: string, value: unknown) => void;
  idwgId?: number;
  floc?: Partial<FunctionalLocation>;
}>;

export default function Form({setFormValue, idwgId, floc}: TForm) {
  const {editCml} = useCmlStore();
  const [flocs, setFlocs] = useState<AppListItem[]>(floc ? [{
    label: `${floc.floc} - ${floc.description?.replace('"', '\'\'')}`,
    value: floc.id,
  }] : []);
  const [classes, setClasses] = useState<AppListItem[]>([]);
  const [codeGroups, setCodeGroups] = useState<AppListItem<RefCmlCodeGroup>[]>([]);
  const [selectedClass, setSelectedClass] = useState<string>();
  const [selectedTechniqueId, setSelectedTechniqueId] = useState<number>();
  const [techniques, setTechniques] = useState<AppListItem[]>([]);
  const [parts, setParts] = useState<AppListItem[]>([]);
  const [grids, setGrids] = useState<AppListItem[]>([]);
  const [accessibilities, setAccessibilities] = useState<AppListItem[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<string | null>();
  const [selectedUnitSuffix, setSelectedUnitSuffix] = useState<string>();
  const [getGridsByIdwgIdApi] = useLazyQuery<GetGridsByIdwgIdQuery>(GRIDS_GET_BY_IDWG_ID);
  const [getGridsBySectorIdApi] = useLazyQuery<GetGridsBySectorIdQuery>(GRIDS_GET_BY_SECTOR_ID);
  const setFlocsFromQueryDatas = (flocs: Partial<FunctionalLocation>[]) => {
    setFlocs(flocs.map(floc => ({
      label: `${floc.floc} - ${floc.description?.replace('"', '\'\'')}`,
      value: floc.id,
    })).sort((a, b) => a.label.localeCompare(b.label)));
  };

  const {loading: flocsLoading} = floc ? {loading: false} : (idwgId
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ? useQuery<GetFlocsByIdwgIdQuery>(FLOCS_GET_BY_IDWG_ID, {
      variables: {idwgId},
      onCompleted(data) {
        setFlocsFromQueryDatas(data.functionalLocations);
      },
      fetchPolicy: 'no-cache',
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    : useQuery<GetAllFlocsAutocompleteQuery>(FLOCS_GET_ALL_AUTOCOMPLETE, {
      onCompleted(data) {
        setFlocsFromQueryDatas(data.functionalLocations);
      },
    }));

  const {loading: codeGroupsLoading, data: codeGroupsQuery} = useQuery<GetAllRefCmlCodeGroupsQuery>(CML_REF_CODE_GROUPS_GET_MANY, {
    onCompleted(data) {
      const classes = data.refCmlCodeGroups.reduce((obj, codeGroup) => {
        const codeGrp = codeGroup as RefCmlCodeGroup;
        obj[codeGrp.class.class] = {
          label: `[${codeGrp.class.class}]${codeGrp.class.description ? ` - ${codeGrp.class.description}` : ''}`,
          value: codeGrp.class.class,
        };
        return obj;
      }, {} as Record<string, AppListItem>);
      setClasses(Object.values(classes).sort((a, b) => a.label.localeCompare(b.label)));
    },
  });
  const {loading: techniquesLoading} = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY, {
    onCompleted(data) {
      setTechniques(data.refEventTechniques.map((technique: Partial<RefEventTechnique>) => ({
        label: `[${technique.technique}]${technique.description ? ` - ${technique.description}` : ''}`,
        value: technique.id,
      } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)));
    },
  });
  const {loading: partsLoading} = useQuery<GetAllRefFlocPartsQuery>(FLOC_REF_PARTS_GET_MANY, {
    onCompleted(data) {
      setParts(data.refFlocParts.map((part: Partial<RefFlocPart>) => ({
        label: `[${part.part}]${part.description ? ` - ${part.description}` : ''}`,
        value: part.id,
      } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)));
    },
  });
  // const {loading: gridsLoading} = useQuery<GetAllGridsAutocompleteQuery>(GRIDS_GET_ALL_AUTOCOMPLETE, {
  //   onCompleted(data) {
  //     setGrids(data.grids.map((grid: Partial<Grid>) => ({
  //       label: `[${grid.grid}]${grid.description ? ` - ${grid.description}` : ''}`,
  //       value: grid.id,
  //     } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)));
  //   },

  // });
  const {loading: catalogsLoading} = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY, {
    onCompleted(data) {
      setAccessibilities(data.findManyRefFlocCatalogs
        .filter(c => (c as RefFlocCatalogs).category === EFLOC_CATALOGS_CATEGORY.ACCESSIBILITY)
        .map((catalog: Partial<RefFlocCatalogs>) => ({
          label: `[${catalog.code}]${catalog.description ? ` - ${catalog.description}` : ''}`,
          value: catalog.id,
        } as AppListItem)).sort((a, b) => a.label.localeCompare(b.label)));
    },

  });

  useEffect(() => {
    const codeGrps = codeGroupsQuery?.refCmlCodeGroups
      .filter(cg => (cg as RefCmlCodeGroup).class.class === selectedClass)
      .map((cg: Partial<RefCmlCodeGroup>) => ({
        label: `[${cg.codeGroup}]${cg.description ? ` - ${cg.description}` : ''}`,
        value: cg.id,
        item: cg as RefCmlCodeGroup,
      }))?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];
    setCodeGroups(codeGrps);
    if (codeGrps.length === 1) {
      setFormValue?.('codeGroupId', codeGrps[0].value);
      if (!isStringNotEmpty(editCml?.description)) {
        setFormValue?.('description', codeGrps[0]?.item?.defaultDesc ?? '');
      }

      if (codeGrps[0].item.defaultTechniqueId) {
        setSelectedTechniqueId(codeGrps[0].item.defaultTechniqueId);
      }

      setSelectedUnit(codeGrps[0].item?.unit);
    } else {
      setFormValue?.('codeGroupId', undefined);
      setSelectedUnit(undefined);
    }
  }, [codeGroupsQuery, selectedClass]);

  useEffect(() => {
    if (flocs.length === 1) {
      setFormValue?.('flocId', flocs[0].value);
    }
  }, [flocs]);

  useEffect(() => {
    if (selectedTechniqueId) {
      setFormValue?.('techniqueId', selectedTechniqueId);
    }
  }, [selectedTechniqueId]);

  useEffect(() => {
    if (editCml?.idwgId) {
      getGridsByIdwgIdApi({
        variables: {
          idwgId: editCml?.idwgId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => setGrids((result.data?.grids ?? []).map((grid: Partial<Grid>) => ({
        label: `[${grid.grid}]${grid.description ? ` - ${grid.description}` : ''}`,
        value: grid.id,
      } as AppListItem))));
    } else if (editCml?.flocId) {
      getGridsBySectorIdApi({
        variables: {
          flocId: editCml?.flocId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => setGrids((result.data?.grids ?? []).map((grid: Partial<Grid>) => ({
        label: `[${grid.grid}]${grid.description ? ` - ${grid.description}` : ''}`,
        value: grid.id,
      } as AppListItem))));
    } else {
      setGrids([]);
    }
  }, [editCml?.idwgId, editCml?.flocId]);

  useEffect(() => {
    setSelectedUnitSuffix(selectedUnit ? ` (${selectedUnit})` : '');
  }, [selectedUnit]);

  return (
    <div className='flex flex-col px-0.5 mb-6'>

      <div className='flex gap-12'>

        <div className='flex-1 flex flex-col gap-4'>

          {/* ANCHOR Identification */}
          <FormGroupHeader>
            {t('label.identification')}
          </FormGroupHeader>

          <div className='flex gap-4'>

            <div className='w-[80%] overflow-hidden'>
              {/* ANCHOR Floc */}
              <RadFormField
                name='flocId'
                render={({field, fieldState}) => (
                  <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.functionalLocation')}>
                    <AppSelect
                      required
                      items={flocs}
                      placeholder={t('label.selectFloc')}
                      loading={flocsLoading}
                      {...field}
                    />
                  </InputLabelWrapper>
                )}
              />
            </div>

            <div className='flex-grow'>
              {/* ANCHOR Position */}
              <RadFormField
                name='position'
                render={({field, fieldState}) => (
                  <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.position')}>
                    <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.POSITION}/>
                  </InputLabelWrapper>
                )}
              />
            </div>

          </div>

          {/* ANCHOR Description */}
          <RadFormField
            name='description'
            render={({field, fieldState}) => (
              <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.description')}>
                <RadInput {...field} maxLength={MAX_LENGTH_VALIDATORS.DESCRIPTION}/>
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Long Description */}
          <RadFormField
            name='longDescription'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} className='w-full' label={t('label.longDescription')}>
                  <RadTextarea
                    {...field}
                    placeholder={t('label.longDescription')}
                    className='h-[148px]'
                    maxLength={MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION}/>
                </InputLabelWrapper>
              )
            }/>

          <div className='grid grid-cols-2 gap-4'>
            {/* ANCHOR Attributes */}
            <FormGroupHeader className='col-span-2'>
              {t('label.attributes')}
            </FormGroupHeader>

            {/* ANCHOR Part */}
            <RadFormField
              name='partId'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.part')}>
                  <AppSelect
                    placeholder={t('label.selectPart')}
                    loading={partsLoading}
                    items={parts}
                    {...field}
                  />
                </InputLabelWrapper>
              )}
            />

            {/* ANCHOR Diameter */}
            <RadFormField
              name='diameter'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.diameter')}>
                  <RadInput {...field}/>
                </InputLabelWrapper>
              )}
            />

          </div>

        </div>

        <div className='w-[400px] flex flex-col gap-4'>
          {/* ANCHOR Classification */}
          <FormGroupHeader>
            {t('label.classification')}
          </FormGroupHeader>

          {/* ANCHOR Class */}
          <InputLabelWrapper isRequired label={t('label.class')}>
            <AppSelect
              required
              items={classes}
              value={selectedClass}
              placeholder={t('label.selectClass')}
              loading={codeGroupsLoading}
              onChange={val => setSelectedClass(val as string)}/>
          </InputLabelWrapper>

          {/* ANCHOR Codegroup */}
          <RadFormField
            name='codeGroupId'
            render={({field, fieldState}) => (
              <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.codeGroup')}>
                <AppSelect
                  key={`codegroup-${selectedClass}`}
                  required
                  items={codeGroups}
                  placeholder={selectedClass ? t('label.selectCodeGroup') : t('label.selectClass')}
                  loading={codeGroupsLoading}
                  onSelectedItemChanged={selectedItem => {
                    setSelectedUnit((selectedItem?.item as RefCmlCodeGroup)?.unit);
                    if (selectedItem?.item?.defaultTechniqueId) {
                      setSelectedTechniqueId(selectedItem?.item?.defaultTechniqueId);
                    }

                    if (!isStringNotEmpty(editCml?.description)) {
                      setFormValue?.('description', selectedItem?.item?.defaultDesc ?? '');
                    }
                  }}
                  {...field}/>
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR technique */}
          <RadFormField
            name='techniqueId'
            render={({field, fieldState}) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.technique')}>
                <AppSelect
                  placeholder={t('label.selectTechnique')}
                  loading={techniquesLoading}
                  items={techniques}
                  {...field}/>
              </InputLabelWrapper>
            )}
          />

          <FormGroupHeader>
            {t('label.location')}
          </FormGroupHeader>

          {/* ANCHOR Grid */}
          <RadFormField
            name='gridId'
            render={({field, fieldState}) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.grid')}>
                <AppCombobox
                  items={grids}
                  placeholder={t('label.selectGrid')}
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />

          {/* ANCHOR Accessibility */}
          <RadFormField
            name='accessibilityId'
            render={({field, fieldState}) => (
              <InputLabelWrapper fieldState={fieldState} label={t('label.accessibility')}>
                <AppSelect
                  placeholder={t('label.selectAccessibility')}
                  loading={catalogsLoading}
                  items={accessibilities}
                  {...field}/>
              </InputLabelWrapper>
            )}
          />

          <div className='grid grid-cols-2 gap-4'>
            {/* ANCHOR Thickness */}
            <FormGroupHeader className='col-span-2'>
              {t('label.thickness')}
            </FormGroupHeader>

            {/* ANCHOR Nominal */}
            <RadFormField
              name='nominal'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.nominal') + selectedUnitSuffix}>
                  <RadInput {...field}/>
                </InputLabelWrapper>
              )}
            />

            {/* ANCHOR alarm1 */}
            <RadFormField
              name='alarm1'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.alarm1') + selectedUnitSuffix}>
                  <RadInput {...field}/>
                </InputLabelWrapper>
              )}

            />

            {/* ANCHOR alarm2 */}
            <RadFormField
              name='alarm2'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.alarm2') + selectedUnitSuffix}>
                  <RadInput {...field}/>
                </InputLabelWrapper>
              )}

            />

            {/* ANCHOR alarm3 */}
            <RadFormField
              name='alarm3'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.alarm3') + selectedUnitSuffix}>
                  <RadInput {...field}/>
                </InputLabelWrapper>
              )}

            />
          </div>

        </div>

      </div>

    </div>
  );
}
