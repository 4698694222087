import {useLazyQuery} from '@apollo/client';
import {Event, EventInspectionPoint, GetLatestPictureByQuery, InspectionPoint, Picture, PictureWhereInput} from '@app/graphql/__types__/graphql';
import {PICTURES_GET_LATEST_BY} from '@app/graphql/requests';
import useInspectionPointStore from '@app/stores/insp';
import {QUALREADING_COLOR} from '@app/utils/constants';
import {isValidUnit} from '@app/utils/functions';
import {RadContextMenu, RadContextMenuTrigger, RadCard, RadCardContent, RadContextMenuContent, RadContextMenuItem, RadBadge} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuCamera, LuExternalLink} from 'react-icons/lu';

type TInspectionPointItem = Readonly<{
  event: Partial<Event>;
  inspectionPoint: Partial<InspectionPoint>;
  eventInspectionPoint?: Partial<EventInspectionPoint>;
  readonly?: boolean;
  hasMenuContext?: boolean;
}>

export default function InspectionPointItem({inspectionPoint, eventInspectionPoint, hasMenuContext, event}: TInspectionPointItem) {
  // const {setInspectionPointToDelete} = useEventStore();
  const [hasPicture, setHasPicture] = useState<boolean>();
  const {setActiveInspectionPoint} = useInspectionPointStore();
  const {t} = useTranslation();
  const [getLatestPictureApi] = useLazyQuery<GetLatestPictureByQuery>(PICTURES_GET_LATEST_BY);

  const getLatestPic = () => {
    const data: PictureWhereInput = {
      inspId: {equals: inspectionPoint!.id!},
    };
    const wrkoId = event?.id;
    if (wrkoId) {
      data.wrkoId = {
        equals: wrkoId,
      };
    }

    return getLatestPictureApi({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      if ((queryResult.data?.findFirstPicture as Picture)?.id) {
        setHasPicture(!!queryResult.data?.findFirstPicture);
      }
    });
  };

  useEffect(() => {
    if (inspectionPoint?.id) {
      getLatestPic();
    }
  }, [inspectionPoint]);

  return (
    <RadContextMenu key={`inspection-point-${inspectionPoint.id}`}>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <RadCard className='rounded-md'>

          <RadCardContent className='flex p-2 gap-2 items-center'>

            <div className='self-stretch rounded-md bg-blue-50 p-2 min-w-14 text-center'>
              <div className='text-primary font-extrabold'>{inspectionPoint?.class?.class}</div>
              <div className='font-extrabold'>{inspectionPoint?.position}</div>
            </div>

            <div className='flex-1 flex flex-col gap-1'>
              <div className='font-bold text-sm'>{inspectionPoint?.description}</div>
              <div className='font-medium text-xs mt-1 mb-1'>
                <RadBadge className='bg-gray-400 hover:bg-gray-400 py-0'>{inspectionPoint.technique?.technique ?? '-'}</RadBadge>
                &nbsp; | &nbsp;
                {inspectionPoint?.functionalLocation?.floc}
              </div>
            </div>

            <div className='flex gap-2 items-center self-stretch'>
              {hasPicture && <LuCamera className='text-primary' size={20}/>}
              <div className='self-stretch rounded-md p-2 bg-gray-200 min-w-14 text-center flex flex-col justify-center' style={{backgroundColor: (eventInspectionPoint?.qualReading?.integrityCondition.color ?? QUALREADING_COLOR) as string}}>
                <div className='font-medium'>{(isValidUnit(inspectionPoint?.char?.unit) ? eventInspectionPoint?.quantReading : eventInspectionPoint?.qualReading?.valCode) ?? '-'}</div>
              </div>
            </div>

          </RadCardContent>

        </RadCard>
      </RadContextMenuTrigger>

      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveInspectionPoint(inspectionPoint)}>
          <LuExternalLink className='mr-2'/> {t('label.open')}
        </RadContextMenuItem>
        {/* <RadContextMenuItem className='item-destructive' disabled={readonly} onClick={() => setInspectionPointToDelete(eventInspectionPoint!)}>
          <LuTrash2 className='mr-2'/> {t('label.removeFromEvent')}
        </RadContextMenuItem> */}
      </RadContextMenuContent>

    </RadContextMenu>
  );
}
