import SimplePageSelector from '@app/components/Common/Tabs/SimplePageSelector';
import {FunctionalLocation} from '@app/graphql/__types__/graphql';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {EFlocRightSideTab} from '@app/utils/enums';
import {useTranslation} from 'react-i18next';
import PicturePage from './PicturePage';
import FlocOverviewDrawing from '@app/components/Common/Block/Floc/FlocBlock/FlocOverviewDrawing';
import DrawingViewers from '@app/components/Common/Block/InspectionDrawing/DrawingViewers';
import EventPage from './EventPage';
import IntegrityPage from './IntegrityPage';

type TProps = Readonly<{
  floc: Partial<FunctionalLocation>;
}>;

export default function RightSide({floc}: TProps) {
  const {t} = useTranslation();
  const {setRightSideTabSelectedValue, rightSideTabSelectedValue, flocInspectionDrawings, setSelectedDrawing, selectedDrawing} = useFlocStore();

  return (
    <div className='h-full w-full flex flex-col'>
      <SimplePageSelector
        className='mt-2'
        items={[
          {label: t('label.picture'), value: EFlocRightSideTab.PICTURES},
          {label: t('label.drawing'), value: EFlocRightSideTab.DRAWINGS},
          {label: t('label.overview'), value: EFlocRightSideTab.OVERVIEW},
          {label: t('label.integrity'), value: EFlocRightSideTab.INTEGRITY},
          {label: t('label.event'), value: EFlocRightSideTab.EVENTS},
        ]}
        selectedValue={rightSideTabSelectedValue}
        onSelectedValueChanged={setRightSideTabSelectedValue}
      />

      <div className={`pt-4 px-4 ${rightSideTabSelectedValue === EFlocRightSideTab.OVERVIEW ? 'pb-0' : 'pb-4'} flex-1 flex flex-col overflow-auto`}>
        <PicturePage visible={rightSideTabSelectedValue === EFlocRightSideTab.PICTURES}/>

        <DrawingViewers objectItem={floc} drawings={flocInspectionDrawings} setSelectedDrawing={setSelectedDrawing} selectedDrawing={selectedDrawing} visible={rightSideTabSelectedValue === EFlocRightSideTab.DRAWINGS}/>

        <FlocOverviewDrawing
          floc={floc}
          visible={rightSideTabSelectedValue === EFlocRightSideTab.OVERVIEW}
        />
        <IntegrityPage visible={rightSideTabSelectedValue === EFlocRightSideTab.INTEGRITY}/>
        <EventPage visible={rightSideTabSelectedValue === EFlocRightSideTab.EVENTS}/>
      </div>
    </div>
  );
}
