import {gql} from '@apollo/client';
import {ASSET_SITES_COMMON_FIELDS} from '../fragments';

export const ASSET_SITES_GET_COUNTER = gql`
query GetAssetSitesCounter {
  aggregateAssetSite {
    _count {
      id
    }
  }
}
`;

export const ASSET_SITES_GET_ALL = gql`
${ASSET_SITES_COMMON_FIELDS}
query GetAssetSites {
  assetSites(orderBy: [ {
     site: asc
  }]) {
    ...AssetSiteCommonFields
  }
}
`;

export const ASSET_SITES_GET_BY_USER = gql`
query GetAssetSitesByUser($userId: String!) {
  userSites(where: {userId: {equals: $userId}}) {
    siteId
    userId
    site {
      site
    }
  }
}
`;

export const ASSET_SITES_ADD_FOR_USER = gql`
mutation AddAssetSitesForUser($sites: [UserSiteCreateManyInput!]!) {
  createManyUserSite(data: $sites) {
    count
  }
}
`;

export const ASSET_SITES_CLEAR_FOR_USER = gql`
mutation ClearAssetSitesForUser($userId: String!) {
  deleteManyUserSite(where: {userId: {equals: $userId}}) {
    count
  }
}
`;

export const USER_SITES_GET_ALL = gql`
query GetAllUserSites {
  userSites {
    siteId
    userId
    site {
      site
    }
  }
}
`;

export const USER_SITES_GET_BY_SITE_ID_AND_USER_IDS = gql`
query GetAllUserSitesBySiteIdAndUserIds($siteId: Int!, $userIds: [String!]) {
  userSites(where: {siteId:{equals: $siteId}, userId: {in: $userIds}}) {
    userId
  }
}
`;
