import {useQuery, FetchResult, useLazyQuery} from '@apollo/client';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import BlockTitle from '@app/components/Common/Text/BlockTitle';
import {Event, EventInspectionPoint, EventInspectionPointUncheckedUpdateInput, GetAllRefMeasPointValCodesQuery, GetEventInspectionPointByIdQuery, InspectionPoint, RefMeasPointValCode} from '@app/graphql/__types__/graphql';
import {MEASPOINT_REF_VAL_CODES_GET_MANY, WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_ID} from '@app/graphql/requests';
import {TFieldsBlock, TRenderAutocompleteProps} from '@app/types/app';
import {EFieldType} from '@app/utils/enums';
import {isValidUnit, renderQualReadingItem, setObjValueByPath} from '@app/utils/functions';
import React, {TextareaHTMLAttributes, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {RadForm} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import {LuFileEdit} from 'react-icons/lu';
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig';
import {useHolisAuth} from '@holis/auth-client-react';
type TMeasurementBlock = Readonly<{
  eventInspectionPoint: Partial<EventInspectionPoint>;
  event: Partial<Event>;
  inspectionPoint: Partial<InspectionPoint>;
  readonly?: boolean;
  useCurrentDateAndUser?: boolean;
  onFieldChange?: (field: string, value: unknown) => void;
}>

const MAX_LENGTH_VALIDATORS = {
  notes: 500,
  quantReading: 100,
};

export default function MeasurementBlock({event, eventInspectionPoint, inspectionPoint, readonly, onFieldChange, useCurrentDateAndUser}: TMeasurementBlock) {
  const {t} = useTranslation();
  const config = useOptimusConfig();
  const {user} = useHolisAuth();
  const [updateData, setUpdateData] = useState<EventInspectionPointUncheckedUpdateInput>({});
  const refMeasPointValCodesResult = useQuery<GetAllRefMeasPointValCodesQuery>(MEASPOINT_REF_VAL_CODES_GET_MANY);
  const [activeEventInspectionPoint, setActiveEventInspectionPoint] = useState<Partial<EventInspectionPoint>>(eventInspectionPoint);
  const [initEventInsp, setInitEventInsp] = useState<Partial<EventInspectionPoint>>(eventInspectionPoint);
  const [getEventInspByIdApi] = useLazyQuery<GetEventInspectionPointByIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_ID, {
    fetchPolicy: 'no-cache',
  });
  const zodFormObject = z.object({
    quantReading: z.string().max(MAX_LENGTH_VALIDATORS.quantReading),
    notes: z.string().max(MAX_LENGTH_VALIDATORS.notes),
  });
  const form = useForm<z.infer<typeof zodFormObject>>(
    {resolver: zodResolver(zodFormObject), values: {
      quantReading: activeEventInspectionPoint?.quantReading?.toString() ?? '',
      notes: activeEventInspectionPoint?.notes?.toString() ?? '',
    }, mode: 'onBlur'});
  const htmlForm = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (!!inspectionPoint.codeGroup && !inspectionPoint.codeGroup?.codeGroupValCodes.length && activeEventInspectionPoint.qualReading !== null) {
      const editedInspectionPoint = {...activeEventInspectionPoint};
      setObjValueByPath(editedInspectionPoint, 'qualReading', null);
      setObjValueByPath(editedInspectionPoint, 'qualReadingId', null);
      setActiveEventInspectionPoint(editedInspectionPoint);
      setUpdateData({
        qualReadingId: {
          set: null,
        },
      });
      onFieldChange?.('qualReadingId', null);
      onFieldChange?.('qualReading', null);
    }
  }, [inspectionPoint.codeGroup]);

  const renderAutocomplete = (props: TRenderAutocompleteProps) : React.ReactNode => {
    const {setInputValue, renderMenuItemLabel, dbValue, field, foreignField} = props ?? {};
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedInspectionPoint = {...activeEventInspectionPoint};
            setObjValueByPath(editedInspectionPoint, foreignField!, item);
            setObjValueByPath(editedInspectionPoint, field!, item?.id ?? null);
            setActiveEventInspectionPoint(editedInspectionPoint);
            onFieldChange?.(field!, item?.id);
            setUpdateData({
              [field!]: {
                set: item?.id ?? null,
              },
            });
          }
        }}
        {...props}
      />
    );
  };

  const fieldBlocks: TFieldsBlock[] = [
    {
      title: false,
      fields: [
        ...(isValidUnit(inspectionPoint.char?.unit) ? [{
          label: <>{t('label.measurement')}</>,
          field: 'quantReading',
          fieldType: EFieldType.text,
          initialValue: initEventInsp?.quantReading ?? '',
          value: activeEventInspectionPoint?.quantReading ?? '',
          className: 'w-[300px] mr-6',
          isDisabled: !isValidUnit(inspectionPoint.char?.unit),
          labelClassName: 'w-[120px]',
          labelInputProps: {
            suffixInputComponent: isValidUnit(inspectionPoint.char?.unit) ? <span className='ml-1 w-full lowercase flex flex-col justify-center'>{inspectionPoint.char!.unit!.toLowerCase()}</span> : undefined,
          },
        }] : []),
        {
          label: 'label.reading',
          field: 'qualReadingId',
          foreignField: 'qualReading',
          foreignObject: activeEventInspectionPoint.qualReading,
          initialValue: renderQualReadingItem(initEventInsp?.qualReading),
          value: renderQualReadingItem(activeEventInspectionPoint!.qualReading),
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: refMeasPointValCodesResult,
          getItemsFromResult: (result: FetchResult) => (((result as FetchResult<GetAllRefMeasPointValCodesQuery>)?.data?.refMeasPointValCodes ?? []) as Partial<RefMeasPointValCode>[]).filter((valC: Partial<RefMeasPointValCode>) => !inspectionPoint.codeGroup || !!inspectionPoint.codeGroup.codeGroupValCodes?.map(cgVal => cgVal.valCodeId).includes(valC.id!)),
          renderMenuItemLabel: field => renderQualReadingItem(field as Partial<RefMeasPointValCode>),
          renderInput: renderAutocomplete,
          className: `flex-1 flex ${!isValidUnit(inspectionPoint.char?.unit) ? 'min-w-full' : 'min-w-[calc(100%-330px)]'}`,
          labelClassName: 'w-[100px]',
        },
        {
          label: 'label.date',
          field: 'reportingDate',
          fieldType: EFieldType.date,
          initialValue: initEventInsp?.reportingDate,
          value: useCurrentDateAndUser ? Date.now : activeEventInspectionPoint.reportingDate,
          className: 'w-[300px] mr-6',
          isDisabled: true,
          labelClassName: isValidUnit(inspectionPoint.char?.unit) ? 'w-[120px]' : 'w-[100px]',
        },
        {
          label: 'label.reader',
          field: 'reader',
          fieldType: EFieldType.text,
          initialValue: initEventInsp?.reader,
          value: useCurrentDateAndUser ? user?.username : activeEventInspectionPoint.reader,
          className: 'flex flex-1 min-w-[calc(100%-330px)]',
          isDisabled: true,
          labelClassName: 'w-[100px]',
        },
        {
          label: 'label.notes',
          field: 'notes',
          fieldType: EFieldType.text,
          initialValue: initEventInsp?.notes,
          value: activeEventInspectionPoint.notes,
          className: 'flex w-full',
          inputComponent: 'textarea',
          labelClassName: 'w-[100px]',
          inputProps: {
            rows: 2,
          } as TextareaHTMLAttributes<HTMLTextAreaElement>,
        },
      ],
      fieldsClassName: 'w-full flex flex-row flex-wrap gap-0',
      fieldClassName: 'inline-flex w-auto',
    },
  ];

  const getEventInspectionPointDetail = () => {
    if (eventInspectionPoint.id!) {
      getEventInspByIdApi({
        variables: {
          id: eventInspectionPoint.id!,
        },
        fetchPolicy: 'no-cache',
      }).then(fetchResult => {
        setInitEventInsp({...fetchResult.data?.eventInspectionPoint! as Partial<EventInspectionPoint>});
        setActiveEventInspectionPoint({...fetchResult.data?.eventInspectionPoint! as Partial<EventInspectionPoint>});
      });
    }
  };

  const handleFieldChange = (field: string, value: unknown) => {
    const editedInspectionPoint = {...activeEventInspectionPoint};
    setObjValueByPath(editedInspectionPoint, field, value);
    setActiveEventInspectionPoint(editedInspectionPoint);
    setUpdateData({
      [field!]: {
        set: value,
      },
    });
    onFieldChange?.(field, value);
  };

  useEffect(() => {
    getEventInspectionPointDetail();
  }, [eventInspectionPoint]);

  return (
    <div className='flex flex-col w-full'>
      <BlockTitle className='uppercase text-primary px-4 font-bold'>
        <LuFileEdit size={20} className='mr-2'/> {t('label.measurement')} ({t('label.event')} {event!.event})
      </BlockTitle>
      <RadForm {...form}>
        <form
          ref={htmlForm}
        >
          <FormFieldsBlock
            isDisabled={readonly || config.getActionIsDisabled('event', 'measurement', event?.status)}
            className='px-2'
            fieldsBlocks={fieldBlocks} onFieldChange={handleFieldChange}/>
        </form>
      </RadForm>
    </div>
  );
}
