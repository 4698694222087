import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import Layout from './components/Layout';
import {useHolisAuth} from '@holis/auth-client-react';
import {RadSonner} from '@holis/react-ui/rad';
import Home from '@pages/Home';
import {
  ROUTE_ADMIN_IMPORT_EXPORT,
  ROUTE_ADMIN_USER_ROLE,
  ROUTE_ANOMALY_MANAGEMENT_DAMAGE,
  ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION,
  ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL,
  ROUTE_DOCUMENTATION,
  ROUTE_DOCUMENTATION_DOC_DETAIL,
  ROUTE_DOCUMENTATION_DOCS,
  ROUTE_DOCUMENTATION_IDWG,
  ROUTE_DOCUMENTATION_IDWG_DETAIL,
  ROUTE_HOME,
  ROUTE_METHOD_ENGINEERING,
  ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION,
  ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL,
  ROUTE_OAUTH_CALLBACK,
  ROUTE_SCHEDULING_EVENT_DETAIL,
  ROUTE_SCHEDULING_EVENTS,
  ROUTE_SCHEDULING_EXTERNAL_IMPORT,
  ROUTE_SCHEDULING_INSPECTION_HISTORY,
  ROUTE_SCHEDULING_INSPECTION_HISTORY_DETAIL,
  ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL,
  ROUTE_SCHEDULING_INSPECTION_PLANS,
  ROUTE_WORKPACK,
  ROUTE_WORKPACK_EXEC,
  ROUTE_WORKPACK_EXEC_DETAIL,
  ROUTE_WORKPACK_PREP,
  ROUTE_WORKPACK_PREP_DETAIL,
  ROUTE_WORKPACK_REP,
  ROUTE_WORKPACK_REP_DETAIL,
} from '@utils/constants';
import {Suspense, useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import theme from 'tailwindcss/defaultTheme';
import SpinnerLoader from './components/Loaders/SpinnerLoader';
import ImportExportPage from './pages/Admin/ImportExport';
import UserRolePage from './pages/Admin/UserRole';
import AnomalyManagementDamagePage from './pages/AnomalyManagement/Damage';
import AnomalyManagementNotificationDetailPage from './pages/AnomalyManagement/Notification/Details';
import AnomalyManagementNotificationPage from './pages/AnomalyManagement/Notification/List';
import DocumentationPage from './pages/Documentation';
import DocumentationAssetPage from './pages/Documentation/Document';
import DocumentationAssetDetailsPage from './pages/Documentation/Document/Details';
import IdwgPage from './pages/Documentation/idwg';
import IdwgDetailsPage from './pages/Documentation/idwg/Details';
import Error404 from './pages/Errors/NotFound';
import OauthCallback from './pages/Home/oauth-callback';
import MethodEngineeringPage from './pages/MethodEngineering';
import FunctionalLocationPage from './pages/MethodEngineering/FunctionalLocation';
import FunctionalLocationDetailsPage from './pages/MethodEngineering/FunctionalLocation/Details';
import SchedulingEvents from './pages/Scheduling/Events';
import SchedulingEventDetailsPage from './pages/Scheduling/Events/Details';
import SchedulingInspectionPlanPage from './pages/Scheduling/InspectionPlan';
import SchedulingInspectionPlanDetailsPage from './pages/Scheduling/InspectionPlan/Details';
import WorkPackPage from './pages/WorkPack';
import WorkPackExecutionPage from './pages/WorkPack/Execution';
import WorkPackPreparationPage from './pages/WorkPack/Preparation';
import WorkPackReportingPage from './pages/WorkPack/Reporting';
import UploadDownloadService from './services/api/UploadDownloadService';
import ConfigService from './services/config';
import {UserPermissionsService} from './services/permissions';
import {useDataStore} from './stores/data';
import {useLayoutStore} from './stores/layout';
import useUserPermissions from './utils/hooks/useUserPermissions';
import AppLoading from './components/Layout/AppLoading';
import AppError from './components/Layout/AppError';
import {OptimusClientConfig} from './utils/clientConfig';
import {useLazyQuery} from '@apollo/client';
import {UserSite, GetAssetSitesByUserQuery} from './graphql/__types__/graphql';
import {ASSET_SITES_GET_BY_USER} from './graphql/requests';
import ExternalWorkorderListPage from './pages/Scheduling/ExternalWorkorders';
import InspectionHistory from './pages/Scheduling/InspectionHistory';

export default function App() {
  const {loading} = useLayoutStore();
  const {setUploadDownloadService, setSites, setSelectedSites, selectedSites} = useDataStore();
  const {getAccessToken, user} = useHolisAuth();
  const prms = useUserPermissions();
  const [getSitesApi] = useLazyQuery<GetAssetSitesByUserQuery>(ASSET_SITES_GET_BY_USER, {
    fetchPolicy: 'no-cache',
  });

  UserPermissionsService.init(getAccessToken); // Load permissions
  ConfigService.init(getAccessToken); // Load config

  const createUploadFileSerrvice = () => {
    setUploadDownloadService(new UploadDownloadService(getAccessToken));
  };

  useEffect(() => {
    createUploadFileSerrvice();
    getSitesApi({
      variables: {userId: user!.id!},
    }).then(res => {
      const sites = res.data?.userSites ?? [];
      setSites(sites as Array<Partial<UserSite>>);
      if (sites.length && !selectedSites?.length) {
        setSelectedSites(sites.map(site => site.site.site));
      }
    }).catch(() => setSites([]));
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <div className='font-Inter h-screen w-screen overflow-hidden'>
        {
          (prms.state.error && <AppError error={prms.state.error}/>) || (
            (!prms.state.loaded && <AppLoading/>)
          || <>
            <BrowserRouter basename={import.meta.env.BASE_URL}>
              <Layout projectName={OptimusClientConfig.current.projectName} companyLogo={`${import.meta.env.BASE_URL || '/'}company-logo.png`}>
                <Suspense fallback={<SpinnerLoader isLoading/>}>
                  <Routes>
                    <Route path='*' Component={Error404}/>
                    <Route path={ROUTE_OAUTH_CALLBACK} Component={OauthCallback}/>
                    <Route path={ROUTE_HOME} Component={Home}/>
                    <Route
                      path={ROUTE_METHOD_ENGINEERING}
                      Component={MethodEngineeringPage}
                    />
                    <Route
                      path={ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION}
                      Component={FunctionalLocationPage}
                    />
                    <Route
                      path={ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL}
                      Component={FunctionalLocationDetailsPage}
                    />
                    <Route
                      path={ROUTE_WORKPACK}
                      Component={WorkPackPage}
                    />
                    <Route
                      path={ROUTE_WORKPACK_PREP}
                      Component={WorkPackPreparationPage}
                    />
                    <Route
                      path={ROUTE_SCHEDULING_EXTERNAL_IMPORT}
                      Component={ExternalWorkorderListPage}
                    />
                    <Route
                      path={ROUTE_WORKPACK_PREP_DETAIL}
                      Component={SchedulingEventDetailsPage}
                    />
                    <Route
                      path={ROUTE_WORKPACK_EXEC}
                      Component={WorkPackExecutionPage}
                    />
                    <Route
                      path={ROUTE_WORKPACK_EXEC_DETAIL}
                      Component={SchedulingEventDetailsPage}
                    />
                    <Route
                      path={ROUTE_SCHEDULING_INSPECTION_HISTORY}
                      Component={InspectionHistory}
                    />
                    <Route
                      path={ROUTE_WORKPACK_REP}
                      Component={WorkPackReportingPage}
                    />
                    <Route
                      path={ROUTE_WORKPACK_REP_DETAIL}
                      Component={SchedulingEventDetailsPage}
                    />
                    <Route path={ROUTE_DOCUMENTATION} Component={DocumentationPage}/>
                    <Route
                      path={ROUTE_DOCUMENTATION_DOCS}
                      Component={DocumentationAssetPage}
                    />
                    <Route
                      path={ROUTE_DOCUMENTATION_DOC_DETAIL}
                      Component={DocumentationAssetDetailsPage}
                    />
                    <Route
                      path={ROUTE_DOCUMENTATION_IDWG}
                      Component={IdwgPage}
                    />
                    <Route
                      path={ROUTE_DOCUMENTATION_IDWG_DETAIL}
                      Component={IdwgDetailsPage}
                    />
                    <Route path={ROUTE_ANOMALY_MANAGEMENT_DAMAGE} Component={AnomalyManagementDamagePage}/>
                    <Route path={ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION} Component={AnomalyManagementNotificationPage}/>
                    <Route path={ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL} Component={AnomalyManagementNotificationDetailPage}/>
                    <Route
                      path={ROUTE_ADMIN_IMPORT_EXPORT}
                      Component={ImportExportPage}
                    />
                    <Route
                      path={ROUTE_ADMIN_USER_ROLE}
                      Component={UserRolePage}
                    />
                    <Route
                      path={ROUTE_SCHEDULING_INSPECTION_PLANS}
                      Component={SchedulingInspectionPlanPage}
                    />
                    <Route
                      path={ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL}
                      Component={SchedulingInspectionPlanDetailsPage}
                    />
                    <Route
                      path={ROUTE_SCHEDULING_EVENTS}
                      Component={SchedulingEvents}
                    />
                    <Route
                      path={ROUTE_SCHEDULING_EVENT_DETAIL}
                      Component={SchedulingEventDetailsPage}
                    />
                    <Route
                      path={ROUTE_SCHEDULING_INSPECTION_HISTORY_DETAIL}
                      Component={SchedulingEventDetailsPage}
                    />
                  </Routes>
                </Suspense>
              </Layout>
            </BrowserRouter>
            <RadSonner richColors theme='system'/>
          </>
          )
        }
      </div>
      <SpinnerLoader isLoading={loading}/>
    </ThemeProvider>
  );
}
