import {useLazyQuery} from '@apollo/client';
import {GetReportByNameQuery, Report, ReportFileStatus} from '@app/graphql/__types__/graphql';
import {FILE_REPORTS_GET_BY_NAME} from '@app/graphql/requests';
import {useEffect, useState} from 'react';
import {EDownloadFileType, REPORT_GENERATION_STATUS} from '../enums';
import {OptimusClientConfig} from '../clientConfig';
import AppNotifications from '@app/services/notification';
import {useHolisAuth} from '@holis/auth-client-react';
import {useTranslation} from 'react-i18next';
import {addTokenToUrl, downloadFileFromUrl} from '../functions';
import {useLayoutStore} from '@app/stores/layout';
import axios from 'axios';

export enum ItemType {
  event = 'EVENT',
  notif = 'NOTIF',
  plan = 'PLAN',
}

export const getFileHeader = (type: ItemType) => {
  switch (type) {
    case ItemType.event:
      return 'EVENT-';
    case ItemType.notif:
      return '';
    case ItemType.plan:
      return 'PLAN-';
    default:
      return '';
  }
};

export const getDocNameTypeLabel = (type: ItemType) => {
  switch (type) {
    case ItemType.event:
      return 'EVENT';
    case ItemType.notif:
      return 'IAN';
    case ItemType.plan:
      return 'PLAN';
    default:
      return '';
  }
};

export const getGenerationUrlTypeLabel = (type: ItemType) => {
  switch (type) {
    case ItemType.event:
      return 'event';
    case ItemType.notif:
      return 'notification';
    case ItemType.plan:
      return 'plan';
    default:
      return '';
  }
};

export default function useReportGeneration(itemType: ItemType, itemId?: number, itemRef?: string) {
  const [hasReport, setHasReport] = useState<boolean>(false);
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false);

  const {getAccessToken} = useHolisAuth();
  const {startLoading, stopLoading} = useLayoutStore();

  const {t} = useTranslation();

  const [getReportByDocNameApi] = useLazyQuery<GetReportByNameQuery>(FILE_REPORTS_GET_BY_NAME);

  const updateReportStatus = async () => {
    const response = await getReportByDocNameApi({
      variables: {
        docName: `${getDocNameTypeLabel(itemType)}-${itemRef}`,
      },
    });
    const report = response.data?.report as Report | undefined;
    setHasReport(report?.status === ReportFileStatus.Completed);
    setIsReportGenerating(report?.status === ReportFileStatus.InProcess);
  };

  const downloadReport = async () => {
    startLoading();
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${`${getDocNameTypeLabel(itemType)}-${itemRef}`}?type=${EDownloadFileType.REPORT}`, getAccessToken);

    downloadFileFromUrl(reportUrl, `${getFileHeader(itemType)}${itemRef}.pdf`, {
      onEnd() {
        stopLoading();
      },
      onFail(err) {
        console.log(err);
        stopLoading();
        AppNotifications.error(t('message.error.default.title'));
      },
      getAccessToken,
    });
  };

  const setSocketListeners = () => {
    OptimusClientConfig.current.socket?.on(REPORT_GENERATION_STATUS.IN_PROCESS, (datas: { id: number }) => {
      if (datas.id === itemId) {
        setIsReportGenerating(true);
      }
    }).on(REPORT_GENERATION_STATUS.FAILED, (datas: { id: number }) => {
      if (datas.id === itemId) {
        setIsReportGenerating(false);
      }

      AppNotifications.error(t('message.report.generation.failed'));
    }).on(REPORT_GENERATION_STATUS.COMPLETED, async (datas: { id: number }) => {
      if (datas.id === itemId) {
        setHasReport(true);
        setIsReportGenerating(false);
      }

      AppNotifications.success(t('message.report.generation.completed'));
    });
  };

  const unsetSocketListeners = () => {
    OptimusClientConfig.current.socket?.off(REPORT_GENERATION_STATUS.IN_PROCESS).off(REPORT_GENERATION_STATUS.FAILED).off(REPORT_GENERATION_STATUS.COMPLETED);
  };

  const launchReportGeneration = async () => {
    startLoading();
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.reportBaseUrl}/${getGenerationUrlTypeLabel(itemType)}/${itemId}`, getAccessToken);

    axios.get(reportUrl).then(_res => {
      setIsReportGenerating(true);
      AppNotifications.success(t('message.report.generation.requestSaved'));
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(stopLoading);
  };

  useEffect(() => {
    if (!itemId) {
      return;
    }

    updateReportStatus();
    setSocketListeners();

    return () => unsetSocketListeners();
  }, [itemType, itemId]);

  return {
    hasReport,
    isReportGenerating,
    launchReportGeneration,
    downloadReport,
  };
}

