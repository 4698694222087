import {gql} from '@apollo/client';
import {CML_REF_CLASSES_COMMON_FIELDS} from './refCmlClasses';

export const CML_REF_CODE_GROUPS_COMMON_FIELDS = gql`
  ${CML_REF_CLASSES_COMMON_FIELDS}
  fragment RefCmlCodeGroupCommonFields on RefCmlCodeGroup {
    id
    codeGroup
    defaultDesc
    description
    shape
    unit
    calcThk
    classId
    class {
      ...RefCmlClassCommonFields
    }
    codeGroupValCodes {
      valCodeId
    }
    defaultTechniqueId
}`;
