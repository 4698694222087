import {useLazyQuery} from '@apollo/client';
import SelectionConfirmModal, {TSelectionConfirmModalWithoutListProps} from '@app/components/Modal/Confirm/Selection';
import {EventView, GetUserRolesByUserIdsQuery, UserRole, GetAllUserSitesBySiteIdAndUserIdsQuery} from '@app/graphql/__types__/graphql';
import {USER_SITES_GET_BY_SITE_ID_AND_USER_IDS} from '@app/graphql/requests';
import {USER_ROLES_GET_BY_USER_IDS} from '@app/graphql/requests/usersRoles';
import {UserWithRole} from '@app/stores/users';
import {useHolisAuth} from '@holis/auth-client-react';
import {TDataRowTreeList} from '@holis/react-ui';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type TUsersToSendReportModal = TSelectionConfirmModalWithoutListProps & Readonly<{
  event: Partial<EventView>;
}>;

export default function UsersToSendReportModal({event, ...restProps}: TUsersToSendReportModal) {
  const {authClient} = useHolisAuth();
  const [usersWithRole, setUsersWithRole] = useState<Array<UserWithRole>>();
  const [getUserRolesByUserIdsApi] = useLazyQuery<GetUserRolesByUserIdsQuery>(USER_ROLES_GET_BY_USER_IDS);
  const [getUsersBySiteIdApi] = useLazyQuery<GetAllUserSitesBySiteIdAndUserIdsQuery>(USER_SITES_GET_BY_SITE_ID_AND_USER_IDS);
  const {t} = useTranslation();
  const getAppUsers = () => {
    authClient?.getAppUsers().then(async users => {
      const userIds = users.map(user => user.id);
      const siteUserIds = !event.schedulingTag_sector_site_id ? userIds : (await getUsersBySiteIdApi({
        variables: {
          siteId: event.schedulingTag_sector_site_id,
          userIds,
        },
      })).data?.userSites.map(userSite => userSite.userId);
      if (siteUserIds?.length) {
        getUserRolesByUserIdsApi({
          variables: {
            ids: siteUserIds,
          },
          fetchPolicy: 'no-cache',
        }).then(queryResult => {
          const userRoles = queryResult.data?.userRoles ?? [];
          setUsersWithRole(users.map(user => ({
            ...user,
            userRole: userRoles.find(userRole => (userRole as UserRole).userId === user.id),
          })));
        }).catch(() => setUsersWithRole(users));
      }
    });
  };

  useEffect(() => {
    getAppUsers();
  }, [authClient]);

  return (
    <SelectionConfirmModal
      title={t('label.users')}
      description={t('label.selectUsersToSendReport')}
      validateBtnLabel={t('label.publish')}
      items={usersWithRole}
      selectedIds={usersWithRole?.filter(userWithRole => !!userWithRole.userRole?.notifyNewReport).map(userWithRole => userWithRole.id)}
      listProps={{
        listProps: {
          isLoading: typeof usersWithRole === 'undefined',
          data: (usersWithRole ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'action',
              title: 'action',
              type: 'string',
              titleHidden: true,
              cellRenderer: 'action',
              filter: false,
            },
            {
              field: 'username',
              title: t('label.username'),
              type: 'string',
              width: 200,
              sort: 'asc',
            },
            {
              field: 'email',
              title: t('label.email'),
              type: 'string',
              width: 300,
            },
            {
              field: 'firstName',
              title: t('label.firstName'),
              type: 'string',
              width: 200,
            },
            {
              field: 'lastName',
              title: t('label.lastName'),
              type: 'string',
              width: 200,
            },
          ],
        },
      }}
      {...restProps}
    />
  );
}
