import {Notification} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import {TAdditionalActions} from '@app/types/app';
import {EAdditionalAction} from '@app/utils/enums';
import useNotificationStore from '@app/stores/notification';
import ItemsSummary, {TSummaryItem} from '@app/components/Common/ItemSummary';
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {LuDownload, LuGitFork, LuRefreshCw, LuTrash2} from 'react-icons/lu';
import useReportGeneration, {ItemType} from '@app/utils/hooks/useReportGeneration';
import ReportGeneratingMesaggeWrapper from '../../../Reporting/ReportGeneratingMesaggeWrapper';

type THeader = Readonly<{
    notification: Partial<Notification>
    readonly?: boolean
}>

export default function Header({notification, readonly}: THeader) {
  const {t} = useTranslation();
  const {downloadReport, hasReport, isReportGenerating, launchReportGeneration} = useReportGeneration(ItemType.notif, notification.id, notification.notif);
  const {changeDeleteNotificationModalDisplay, activeNotification, changeWorkflowModalDisplay, notificationDamages, attachments} = useNotificationStore();
  const itemsSummary: TSummaryItem[] = [
    {
      label: 'damage',
      value: notificationDamages?.length ?? activeNotification?._count?.notificationDamages ?? 0,
    },
    {
      label: 'attachment',
      value: attachments?.length ?? activeNotification?._count?.attachments ?? 0,
    },
  ];

  const handleShowWorkflow = () => {
    changeWorkflowModalDisplay(true);
  };

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DISPLAY_WORKFLOW]: <RadDropdownMenuItem className='gap-2' onClick={handleShowWorkflow}><LuGitFork/> {t('label.workflow')}</RadDropdownMenuItem>,
    [EAdditionalAction.GENERATE_REPORT]: <RadDropdownMenuItem className='gap-2' disabled={isReportGenerating} onClick={launchReportGeneration}><LuRefreshCw className={`text-sm${isReportGenerating ? ' animate-spin' : ''}`}/> {t('label.generateReport')}</RadDropdownMenuItem>,
    ...(hasReport ? {[EAdditionalAction.DOWNLOAD_REPORT]: <RadDropdownMenuItem disabled={isReportGenerating} className='gap-2' onClick={downloadReport}><LuDownload className='text-sm'/> {t('label.downloadReport')}</RadDropdownMenuItem>} : {}),
    [EAdditionalAction.DELETE]: <RadDropdownMenuItem disabled={readonly} className='gap-2 item-destructive' onClick={() => changeDeleteNotificationModalDisplay(true)}><LuTrash2/> {t('label.deleteNotification')}</RadDropdownMenuItem>,
  };

  return (
    <DetailsPageTitleBar
      title={`${activeNotification?.notif}`}
      subtitle={`${activeNotification?.functionalLocation?.floc}`}
      actionButtons={{
        additionalActions,
      }}
    >
      <ReportGeneratingMesaggeWrapper isReportGenerating={isReportGenerating}>
        <ItemsSummary items={itemsSummary}/>
      </ReportGeneratingMesaggeWrapper>
    </DetailsPageTitleBar>
    // <div className='flex w-full text-gray-700 text-sm uppercase items-center gap-2 pr-2'>
    //   {status === ENOTIFICATION_NOTIF_STATUS.INIT && activeNotification?.id === OBJ_NEW_ID && <ElevatedButton className='bg-teal-500 disabled:bg-opacity-70 uppercase w-max font-poppins text-white' disabled={hasError()} onClick={generateIan}><CustomIcon name='edit-check'/> {t('label.generateIan')}</ElevatedButton>}
    //   {status === ENOTIFICATION_NOTIF_STATUS.WAPP && <ElevatedButton className='bg-teal-500 disabled:bg-opacity-70 uppercase w-max font-poppins text-white' onClick={approveIan}><FaRegCheckCircle size={14}/> {t('label.approve')}</ElevatedButton>}
    //   {notification.id !== OBJ_NEW_ID && <ActionButtons
    //     dropdownTransitionClassName='top-[30px]'
    //     additionalActions={additionalActions}
    //   />}

  // </div>
  );
}
