/* eslint-disable complexity */
import {EDMGMarkupForm, EMarkupForm} from '@app/utils/enums';
import {IoTriangle, IoTriangleOutline} from 'react-icons/io5';
import {MdCircle, MdComment, MdDiamond, MdHexagon, MdOutlineCircle, MdOutlineDiamond, MdOutlineSquare, MdSquare} from 'react-icons/md';
import {FaUnsplash} from 'react-icons/fa';
import {twMerge} from 'tailwind-merge';
import {BiSolidCylinder} from 'react-icons/bi';
import {FaDiamondTurnRight} from 'react-icons/fa6';
import {LuTriangleRight} from 'react-icons/lu';
import {CSSProperties} from 'react';
import DMG_BOLT from '@app/assets/images/markups/DMG_BOLT.svg';
import DMG_PAINTBRUSH from '@app/assets/images/markups/DMG_PAINTBRUSH.svg';
import DMG_CIRCLE from '@app/assets/images/markups/DMG_CIRCLE.svg';
import DMG_LAYER from '@app/assets/images/markups/DMG_LAYER.svg';
import DMG_CRACK from '@app/assets/images/markups/DMG_CRACK.svg';
import DMG_CYLINDER from '@app/assets/images/markups/DMG_CYLINDER.svg';
import DMG_SUPP from '@app/assets/images/markups/DMG_SUPP.svg';
import DMG_MECH from '@app/assets/images/markups/DMG_MECH.svg';
import DMG_TOOL from '@app/assets/images/markups/DMG_TOOL.svg';
import DMG_THIN from '@app/assets/images/markups/DMG_THIN.svg';
// import Cylinder from "@app/assets/images/icons/Cylinder";
export type TFormIcon = Readonly<{
    form?: string,
    className?: string,
    size?: number,
    width?: number,
    height?: number
    style?: CSSProperties;
}>;

export const FIRST_OBJ_INVISIBLE_LIST: string[] = [EMarkupForm.CIRCLE_SOLID, EMarkupForm.DIAMOND_SOLID, EMarkupForm.DIAMOND_DOTTED, EMarkupForm.SQUARE_SOLID, EMarkupForm.CIRCLE_DOTTED, EMarkupForm.COMMENT, EMarkupForm.SQUARE_DOTTED, EMarkupForm.HEXAGONE_SOLID];

export default function FormIcon({form, style, className, size, width, height}: TFormIcon) {
  let Markup;
  switch (form) {
    case EDMGMarkupForm.DMG_BOLT:
      Markup = DMG_BOLT;
      break;
    case EDMGMarkupForm.DMG_PAINTBRUSH:
      Markup = DMG_PAINTBRUSH;
      break;
    case EDMGMarkupForm.DMG_CIRCLE:
      Markup = DMG_CIRCLE;
      break;
    case EDMGMarkupForm.DMG_CRACK:
      Markup = DMG_CRACK;
      break;
    case EDMGMarkupForm.DMG_CYLINDER:
      Markup = DMG_CYLINDER;
      break;
    case EDMGMarkupForm.DMG_SUPP:
      Markup = DMG_SUPP;
      break;
    case EDMGMarkupForm.DMG_MECH:
      Markup = DMG_MECH;
      break;
    case EDMGMarkupForm.DMG_TOOL:
      Markup = DMG_TOOL;
      break;
    case EDMGMarkupForm.DMG_LAYER:
      Markup = DMG_LAYER;
      break;
    case EDMGMarkupForm.DMG_THIN:
      Markup = DMG_THIN;
      break;
      // const MarkupName = form;
      // return <MarkupName style={style} width={width ?? size} height={height ?? size} size={size} className={className}/>;
    case EMarkupForm.CIRCLE_SOLID:
      Markup = MdCircle;
      break;
    case EMarkupForm.CIRCLE_DOTTED:
      Markup = MdOutlineCircle;
      break;
    case EMarkupForm.DCIRCLE_SOLID:
      Markup = FaDiamondTurnRight;
      break;
    case EMarkupForm.TRIANGLERECT_SOLID:
      Markup = LuTriangleRight;
      break;
    case EMarkupForm.SQUARE_SOLID:
      Markup = MdSquare;
      break;
    case EMarkupForm.SQUARE_DOTTED:
      Markup = MdOutlineSquare;
      break;
    case EMarkupForm.TRIANGLE_SOLID:
      className = twMerge('mt-0', className);
      Markup = IoTriangle;
      break;
    case EMarkupForm.TRIANGLERECT_DOTTED:
      className = twMerge('mt-0', className);
      Markup = IoTriangleOutline;
      break;
    case EMarkupForm.CYLINDER_SOLID:
      Markup = BiSolidCylinder;
      break;
    case EMarkupForm.HEXAGONE_SOLID:
      Markup = MdHexagon;
      break;
    case EMarkupForm.DIAMOND_SOLID:
      Markup = MdDiamond;
      break;
    case EMarkupForm.DIAMOND_DOTTED:
      Markup = MdOutlineDiamond;
      break;
    case EMarkupForm.SPLASH_SOLID:
      Markup = FaUnsplash;
      break;
    case EMarkupForm.COMMENT:
      Markup = MdComment;
      break;
    default:
      Markup = MdCircle;
      break;
  }

  return <Markup style={style} width={width ?? size} height={height ?? size} size={size} className={className}/>;
}
