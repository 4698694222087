import IntegrityStatusCard from '@app/components/Common/Block/IntegrityStatus/IntegrityStatusBlock/IntegrityStatusCard';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {t} from 'i18next';
import {LuClipboardList} from 'react-icons/lu';

export default function IntegrityStatusBlock() {
  const {integrityStatus} = useFlocStore();

  return (
    <div className='flex flex-col gap-2 px-4 mb-6'>
      <FormGroupHeader className='mb-1'><LuClipboardList className='mr-2' size={20}/> {t('label.integrityStatus')}</FormGroupHeader>
      {integrityStatus ? <IntegrityStatusCard integrityStatus={integrityStatus}/> : <div className='flex justify-center'>{t('label.noResult')}</div>}
    </div>
  );
}
