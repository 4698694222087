
export function ensureDefaultGridLayout(gridName: string, defaultLayoutString: string) {
  const savedLayout = localStorage.getItem(`TREELIST_${gridName}`);
  if (savedLayout) {
    const layoutState = JSON.parse(savedLayout);
    if (layoutState?.state && Object.keys(layoutState.state).length > 0) {
      return;
    }
  }

  localStorage.setItem(`TREELIST_${gridName}`, defaultLayoutString);
}
