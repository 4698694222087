import {useLazyQuery, useQuery} from '@apollo/client';
import PageContent from '@app/components/Layout/PageContent';
import {GetAllRolesQuery, GetUserRolesByUserIdsQuery, UserRole} from '@app/graphql/__types__/graphql';
import {ROLES_GET_ALL, USER_ROLES_GET_BY_USER_IDS} from '@app/graphql/requests/usersRoles';
import useUsersStore from '@app/stores/users';
import {useHolisAuth} from '@holis/auth-client-react';
import {RadTabs, RadTabsContent, RadTabsList, RadTabsTrigger} from '@holis/react-ui/rad';
import {t} from 'i18next';
import {useEffect} from 'react';
import Roles from './components/Roles';
import Users from './components/Users';

export default function UserRolePage() {
  const {authClient} = useHolisAuth();
  const {setUsers, setRoles, setFetchAppUsers, fetchAppUsers} = useUsersStore();
  const [getUserRolesByUserIdsApi] = useLazyQuery<GetUserRolesByUserIdsQuery>(USER_ROLES_GET_BY_USER_IDS);

  const getAppUsers = () => {
    authClient?.getAppUsers().then(users => {
      const userIds = users.map(user => user.id);
      getUserRolesByUserIdsApi({
        variables: {
          ids: userIds,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        const userRoles = queryResult.data?.userRoles ?? [];
        setUsers(users.map(user => ({
          ...user,
          userRole: userRoles.find(userRole => (userRole as UserRole).userId === user.id),
        })));
      }).catch(() => setUsers(users));
    });
  };

  useQuery<GetAllRolesQuery>(ROLES_GET_ALL, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setRoles(data.roles.map(role => ({
        id: role.id,
        role: role.role,
        usersIds: role.users.map(user => user.userId),
        permissions: role.permissions.map(permission => ({
          id: permission.id,
          permissions: permission.permissions,
          resource: permission.resource,
        })),
      })));
    },
  });

  useEffect(() => {
    setFetchAppUsers(getAppUsers);
  }, [authClient]);

  useEffect(() => {
    fetchAppUsers?.();
  }, [fetchAppUsers]);

  return (
    <PageContent>
      <div className='p-6 h-full flex flex-col'>
        <RadTabs defaultValue='users' className='relative h-full'>
          <RadTabsList className='absolute z-10'>
            <RadTabsTrigger value='users'>{t('label.users')}</RadTabsTrigger>
            <RadTabsTrigger value='roles'>{t('label.roles')}</RadTabsTrigger>
          </RadTabsList>

          <RadTabsContent value='users' className='h-full mt-0'>
            <Users/>
          </RadTabsContent>

          <RadTabsContent value='roles' className='h-full mt-0'>
            <Roles/>
          </RadTabsContent>
        </RadTabs>
      </div>
    </PageContent>
  );
}
