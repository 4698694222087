import {useMutation, useQuery} from '@apollo/client';
import {Damage, EventDamage, RefMeasPointValCode, GetEventDamagesByDmgIdQuery, UpdateEventDamageByIdMutation, Event} from '@app/graphql/__types__/graphql';
import {FORMAT_DATE_EU} from '@app/utils/constants';
import moment from 'moment';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IColumn} from '@holis/react-ui';
import {WORKORDER_EVENT_DAMAGES_GET_BY_DMG_ID, WORKORDER_EVENT_DAMAGES_UPDATE_BY_ID} from '@app/graphql/requests/eventDamages';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';
import {EApiOperator} from '@app/utils/enums';
import {useHolisAuth} from '@holis/auth-client-react';
import EventMeasurementQualReading from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementQualReading';
import EventMeasurementTable from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementTable';
import {RadButton} from '@holis/react-ui/rad';
import {LuInfo} from 'react-icons/lu';
import AppCheckbox from '@app/components/Common/Form/Checkbox';
import EventMeasurementNotes from '@app/components/Common/Block/Event/EventModal/components/EventMeasurement/EventMeasurementNotes';

type TEventDamage = Readonly<{
  damage: Partial<Damage>;
  event?: Partial<Event>;
  onCreatedOrUpdated?: (damage: Partial<Damage>, operator?: EApiOperator) => void;
}>

export default function EventDamageHistory({damage, event}: TEventDamage) {
  // const [measPointValCodes, setMeasPointValCodes] = useState<Partial<RefMeasPointValCode>[]>([]);
  const [valCodeModalOpenRow, setValCodeModalOpenRow] = useState<Partial<EventDamage>>();
  const {t} = useTranslation();
  const {user} = useHolisAuth();
  const {startLoading, stopLoading} = useLayoutStore();
  // const refMeasPointValCodesResult = useQuery<GetAllRefMeasPointValCodesQuery>(MEASPOINT_REF_VAL_CODES_GET_MANY);
  const {data, error, loading, refetch} = useQuery<GetEventDamagesByDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_BY_DMG_ID, {
    variables: {
      dmgId: damage.id!,
      orderBy: [
        {reportingDate: {sort: 'desc'}},
        {id: 'desc'},
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const [updateEventDamageByIdApi] = useMutation<UpdateEventDamageByIdMutation>(WORKORDER_EVENT_DAMAGES_UPDATE_BY_ID);

  const handleIsFixedChange = (checked: boolean, item: Partial<EventDamage>) => {
    startLoading();
    updateEventDamageByIdApi({
      variables: {
        id: item.id!,
        data: {
          isFixed: {
            set: checked,
          },
        },
      },
    }).then(() => {
      AppNotifications.success(t('message.success.eventDamageUpdated'));
      refetch();
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  const updateHandler = (item: Partial<EventDamage>, field: string, value: unknown, htmlElem?: HTMLElement) => {
    startLoading();
    updateEventDamageByIdApi({
      variables: {
        id: item.id!,
        data: {
          [field]: {
            set: value,
          },
          reportingDate: {
            set: new Date(),
          },
          reader: {
            set: user?.username,
          },
        },
      },
    })
      .then(() => {
        AppNotifications.success(t('message.success.eventDamageUpdated'));
        refetch();
      })
      .catch((_error: Error) => {
        if (htmlElem) {
          htmlElem.style.border = '1px solid red';
        }

        const errorMessage = t('message.error.default.title');

        AppNotifications.error(errorMessage);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const isEditableRow = (rowData: unknown) => !!event?.id && (rowData as Partial<EventDamage>).wrkoId === event?.id;

  const cellsRenderer = (_editable?: boolean) => ({
    isFixed: (val: string, rowData: unknown) => <AppCheckbox checkedIconClassName='text-primary' checked={!!val} onChange={(checked: boolean) => handleIsFixedChange(checked, rowData as Partial<EventDamage>)}/>,
    notes(val: string, _rowData: unknown) {
      return (
        <EventMeasurementNotes notes={val}/>
      );
    },
    reading(_val: string, rowData: unknown) {
      return (
        <EventMeasurementQualReading<EventDamage, RefMeasPointValCode> updateHandler={updateHandler} dataRow={rowData} isEditableRow={isEditableRow}/>
      );
    },
    reportingDate(_val: string, rowData: unknown) {
      const dataRow = (rowData as Partial<EventDamage>);
      return <span>{dataRow.reportingDate ? moment(dataRow.reportingDate).format(FORMAT_DATE_EU) : ''}</span>;
    },

    event(_val: string, rowData: unknown) {
      const dataRow = (rowData as Partial<EventDamage>);
      return dataRow.event ? <span className={`flex justify-between flex-row items-center pr-2 ${dataRow.wrkoId === event?.id ? 'font-bold' : ''}`}>{dataRow.event.event} <RadButton variant='ghost' size='icon'><LuInfo className='h-4 w-4'/></RadButton></span> : '';
    },
  });
  const columns: IColumn[] = [
    // {
    //   field: 'isFixed',
    //   title: t('label.fixed'),
    //   type: 'boolean',
    //   width: 60,
    //   cellRenderer: 'isFixed',
    //   filter: false,
    // },
    {
      field: 'reportingDate',
      title: t('label.date'),
      type: 'date',
      cellRenderer: 'reportingDate',
      width: 100,
      filter: false,
      sort: 'desc',
    },
    {
      field: 'reading',
      title: t('label.reading'),
      type: 'string',
      width: 250,
      cellRenderer: 'reading',
      filter: false,
    },
    // {
    //   field: 'reader',
    //   title: t('label.reader'),
    //   type: 'string',
    //   width: 80,
    //   filter: false,
    // },
    {
      field: 'notes',
      title: t('label.notes'),
      type: 'string',
      width: 50,
      cellRenderer: 'notes',
      filter: false,
    },
    {
      field: 'event',
      title: t('label.event'),
      type: 'string',
      cellRenderer: 'event',
      width: 140,
      filter: false,
    },
  ];

  // useEffect(() => {
  //   if (refMeasPointValCodesResult.data && !refMeasPointValCodesResult.error && !refMeasPointValCodesResult.loading) {
  //     setMeasPointValCodes((refMeasPointValCodesResult.data?.refMeasPointValCodes as Partial<RefMeasPointValCode>[]).filter((valCode: Partial<RefMeasPointValCode>) => valCode.codeGroupValCodes?.map((codeGrValCode: RefMeasPointCodeGroupValCode) => codeGrValCode.codeGroupId).includes(damage.codeGroupId!)));
  //   }
  // }, [refMeasPointValCodesResult, damage]);

  return (
    <EventMeasurementTable<EventDamage, RefMeasPointValCode>
      className='flex flex-col w-full px-2'
      // qualReadingSelectionModalTitle={t('label.damageValCode')}
      // qualReadingSelectionModalItems={measPointValCodes}
      selectedRow={valCodeModalOpenRow}
      displayTabs={['list']}
      updateHandler={updateHandler}
      items={((data?.eventDamages ?? []) as Partial<EventDamage>[]).filter((item: Partial<EventDamage>) => item.wrkoId !== event?.id)}
      isLoading={loading} error={!!error} cellRenderers={cellsRenderer(false)}
      columns={columns} onQualReadingSelectionModalClose={() => setValCodeModalOpenRow(undefined)}/>

  );
}
